import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Routes from "../Routes";




 function Main(){
     return(
         <Switch>
             {
                Routes.map(item => (
                    <Route path={item.path} key={item.key} exact component={item.component} />
                ))
             }
         </Switch>
     )
}

export default withRouter(Main);
