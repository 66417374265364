import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Typography, Box, Icon, Button } from "@material-ui/core";

import iwant1 from "../assets/iwant1.jpg";
import trialImg from "../assets/trialImg.png";
import pers1 from "../assets/pers1.svg";
import mar1 from "../assets/mar1.svg";
import seam from "../assets/seam.svg";

import AboutUs from "./about";
import Features from "./features";
import Pricing from "./Pricing";
import AppsScreenshot from "./AppScreenshot";
import Faq from "../components/Faq";
import AppDownload from "./AppDownload";
import Clients from "./Clients";
import ContactArea from "./contactArea";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";
import "../index.css";

import { Card } from "@material-ui/core-v4";
import NavBar from "../components/header";

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			height: "auto",
			position: "relative",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundImage: "url(" + iwant1 + ")",
			// background: 'linear-gradient(to right, #00c6ff 0%, #0072ff 100%)',
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			// ObjectFit: 'contain',
			fontFamily: "relaway!important",
		},
		topContainer: {
			width: "100%",
			height: "100%",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			// backgroundImage: 'url('+iwant1+')',
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			opacity: "0.9",
			zIndex: "0",
			padding: "0px",
			margin: "0px",
		},
		waveImg: {
			width: "100%",
			height: "240px",
			position: "relative",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			ObjectFit: "contain",
			background: "url(" + trialImg + ")",
			// backgroundColor: 'linear-gradient(to right, #00c6ff 0%, #0072ff 100%)',
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			[theme.breakpoints.up("lg")]: {
				height: "350px",
			},
			[theme.breakpoints.down("sm")]: {
				height: "250px",
			},
		},
		mainTextcontent: {
			width: "100%",
			height: "400px",
			display: "flex",
			position: "relative",
			justifyContent: "end",
			// zIndex: '1',
			alignItems: "center",
			textAlign: "center",
			color: "white",
			marginTop: "190px",
			padding: "0px",
			[theme.breakpoints.down("sm")]: {
				height: "600px",
				marginTop: "25px",
			},
		},
		BottomContainer: {
			width: "100%",
			height: "100%",
			backgroundColor: "white",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		bottomText: {
			maxWidth: "1200px",
			height: "auto",
			paddingTop: "50px",
			paddingBottom: "50px",
		},
		buttomIcon: {
			width: "50px!important",
			height: "50px!important",
			cursor: "pointer",
		},
		header: {
			fontSize: "50px!important",
			marginTop: "200px!important",
			marginBottom: "5px!important",
			fontFamily: "raleway!important",
			fontWeight: "700!important",
			[theme.breakpoints.down("sm")]: {
				fontSize: "35px!important",
				marginBottom: "60px!important",
			},
		},
		bodyText: {
			fontSize: "15px!important",
			fontWeight: "600!important",
			marginBottom: "20px!important",
			fontFamily: "raleway!important",
			[theme.breakpoints.down("sm")]: {
				fontSize: "15px!important",
			},
		},
		topButton: {
			marginTop: "30px",
			marginBottom: "30px",
		},
		button3: {
			border: "3px solid white !important",
			background: "transparent !important",
			color: "white !important",
			borderRadius: "12px !important",
			margin: "10px!important",
			fontFamily: "raleway!important",
			padding: "5px 60px !important",
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				padding: "5px 30px!important",
			},
			"&:hover": {
				height: "61px",
				background:
					"linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0!important",
				border: "0px!important",
			},
		},
		drawerIconContainer: {
			width: "50px",
			height: "50px",
			display: "flex",
			justifyContent: "top",
			alignItems: "center",
			position: "absolute!important",
			top: "5px",
			right: "10px",
			marginLeft: "auto",
			"&:hover": {
				backgroundColor: "transparent",
			},
		},
		listContainer: {
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0!important",
			width: "200px!important",
			height: "auto",
		},
		listItemText: {
			...theme.typography.tab,
			color: "white",
			capacity: 0.7,
		},
		listItemtextselected: {
			capacity: 1,
		},
		drawerIcon: {
			height: "70px",
			width: "70px",
			// position: 'absolute',
		},
		helpButton: {
			width: "90px",
			height: "40px",
			display: "flex",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0!important",
			color: "white!important",
			padding: "5px 10px",
			borderRadius: "20px!Important",
		},
		card1: {
			padding: "20px",
			"&:hover": {
				borderBottom: "2px solid #0072ff ",
			},
		},
	};
});

function StartingPage(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [hoverCard, setHoverCard] = useState({
		card1: false,
		card2: false,
		card3: false,
	});

	return (
		<div id='home'>
			<React.Fragment>
				<NavBar />
				<Grid container className={classes.mainContainer}>
					<Grid item className={classes.topContainer}>
						<Grid
							item
							className={classes.mainTextcontent}
							direction='column'
							sx={{ position: "relative" }}>
							<Box
								sx={{
									paddingRight: "10px",
									paddingLeft: "10px",
									position: "absolute",
								}}
								data-aos='zoom-in-up'
								data-aos-duration='1000'>
								<Typography className={classes.header}>
									<b>Start selling online.....</b>
								</Typography>
								<Typography className={classes.bodyText}>
									Millions of Merchants participate in the digital economy, are you?
									<br /> Launch Your Own eCommerce Platform with iOS, Android Mobile App & Website
									for your business.
								</Typography>
								<Typography className={classes.bodyText} style={{ lineHeight: "0.5" }}>
									<a
										href='/getstarted'
										style={{ textDecoration: "none", color: "white", padding: "4px" }}>
										<b>Get 30 days free now!</b>
									</a>
								</Typography>
								<Box className={classes.topButton}>
									<Button
										className={classes.button3}
										variant='outlined'
										onClick={() => {
											props.history.push("/digital-village");
										}}
										sx={{
											fontSize: { xs: "16px", sm: "24px" },
											marginTop: { xs: 1, md: 2, lg: 3 },
										}}
										startIcon={
											<img
												src='https://mp-farmersmarket.cartnshop.com/_next/static/media/dviLogo.b0bbf44c.png'
												alt=''
												style={{
													height: 45,
													width: "auto",
													objectFit: "contain",
													marginRight: "10px",
												}}
											/>
										}>
										<span>
											<b>Digital villages initiative</b>
										</span>
									</Button>
								</Box>
							</Box>
						</Grid>
						<Grid item className={classes.waveImg} direction='column'></Grid>
					</Grid>

					<Grid item className={classes.BottomContainer}>
						{/* <Box style={{width: '1200px',height: 'auto',margin: '20px'}}> */}
						<Grid container className={classes.bottomText} spacing={2}>
							<Grid
								item
								lg={4}
								md={4}
								sm={12}
								xs={12}
								data-aos='fade-right'
								data-aos-duration='2000'>
								<Card
									className={classes.card1}
									elevation={hoverCard.card1 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card1: true });
									}}
									onMouseLeave={() => {
										setHoverCard({ card1: false });
									}}>
									<Grid item container style={{ margin: "10px" }}>
										<Grid item xs={3} sm={2} md={3} lg={3}>
											<Icon className={classes.buttomIcon}>
												<img src={pers1} alt='pers1' />{" "}
											</Icon>
										</Grid>
										<Grid item xs={9} sm={10} md={9} lg={9}>
											{/* <Box style={{marginRight: '20px'}}> */}
											<Typography
												style={{
													color: "#222",
													fontFamily: "raleway",
													fontSize: "18px",
													fontWeight: "700",
												}}>
												PERSONALIZE YOUR STORE
											</Typography>
											<Typography
												style={{ color: "#444", fontFamily: "raleway", fontSize: "14px" }}>
												Create your personalized store with a few clicks and roll out your eCommerce
												website and mobile App on the go!{" "}
											</Typography>
											{/* </Box> */}
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12} data-aos='zoom-in' data-aos-duration='2000'>
								<Card
									className={classes.card1}
									elevation={hoverCard.card2 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card2: true });
									}}
									onMouseLeave={() => {
										setHoverCard({ card2: false });
									}}>
									<Grid item container style={{ margin: "10px" }}>
										<Grid item xs={3} sm={2} md={3} lg={3}>
											<Icon className={classes.buttomIcon}>
												<img src={mar1} alt='mar1' />{" "}
											</Icon>
										</Grid>
										<Grid item xs={9} sm={10} md={9} lg={9}>
											{/* <Box style={{marginRight: '20px'}}> */}
											<Typography
												style={{
													color: "#222",
													fontFamily: "raleway",
													fontSize: "18px",
													fontWeight: "700",
												}}>
												MARKET YOUR BUSINESS
											</Typography>
											<Typography
												style={{ color: "#444", fontFamily: "raleway", fontSize: "14px" }}>
												Promote your business and bring your customers on board with effective
												marketing tools.{" "}
											</Typography>
											{/* </Box> */}
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid
								item
								container
								lg={4}
								md={4}
								sm={12}
								xs={12}
								data-aos='fade-left'
								data-aos-duration='2000'>
								<Card
									className={classes.card1}
									elevation={hoverCard.card3 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card3: true });
									}}
									onMouseLeave={() => {
										setHoverCard({ card3: false });
									}}>
									<Grid item container style={{ margin: "10px" }}>
										<Grid item xs={3} sm={2} md={3} lg={3}>
											<Icon className={classes.buttomIcon}>
												<img src={seam} alt='seam' />{" "}
											</Icon>
										</Grid>
										<Grid item xs={9} sm={10} md={9} lg={9}>
											{/* <Box style={{marginRight: '20px'}}> */}
											<Typography
												style={{
													color: "#222",
													fontFamily: "raleway",
													fontSize: "18px",
													fontWeight: "700",
												}}>
												SEAMLESS INTEGRATIONS
											</Typography>
											<Typography
												style={{ color: "#444", fontFamily: "raleway", fontSize: "14px" }}>
												Integrate your Payment Gateways, Logistics Gateways and Messaging Gateways
												with ease.{" "}
											</Typography>
											{/* </Box> */}
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						{/* </Box> */}
					</Grid>
					<Grid
						item
						style={{
							position: "fixed",
							bottom: "15px",
							right: "15px",
							zIndex: "15000",
						}}>
						<Button
							className={classes.helpButton}
							variant='contained'
							style={{ textTransform: "none" }}>
							<span>? Help</span>
						</Button>
					</Grid>
				</Grid>
				<div id='about'>
					<AboutUs />
				</div>
				<div id='feature'>
					<Features />
				</div>
				<div id='screenshot'>
					<AppsScreenshot />
				</div>
				<div id='pricing'>
					<Pricing />
				</div>
				<div id='faq'>
					<Faq />
				</div>
				<div id='download'>
					<AppDownload />
				</div>
				<div id='client'>
					<Clients />
				</div>
				<div id='contact'>
					<ContactArea />
				</div>
			</React.Fragment>
		</div>
	);
}

export default withRouter(StartingPage);
