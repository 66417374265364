let token =  'Basic aXRwbDppd2FudHVubGltaXRlZA=='

export const urls = {
    headers : {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') :token ,
        "itpl-app-name": "Merchant-Registration-App",
        "itpl-app-version": "1.0-101",
        "itpl-app-type": "web",
        'itpl-client-id':"SS7052021-001",
        'itpl-app-os': 'web',
    },
};
