import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Typography, Container, Box } from "@material-ui/core";

import Pages from "../assets/pages-hover.png";
import DesignHover from "../assets/design-hover.png";
import CatalogHover from "../assets/catalog-hover.png";
import FilterHover from "../assets/filter-hover.png";
import OrderHover from "../assets/order-hover.png";
import IconHover1 from "../assets/IconHover1.png";
import IconHover2 from "../assets/IconHover2.png";
import IconHover3 from "../assets/IconHover3.png";
import IconHover4 from "../assets/IconHover4.png";
import IconHover5 from "../assets/IconHover5.png";
import IconHover6 from "../assets/IconHover6.png";
import Bg3 from "../assets/3.png";
import lineShape from "../assets/lineShape.png";
import WishlistHover from "../assets/wishlist-hover.png";

const useStyles = makeStyles((theme) => {
	return {
		FeaturesMain: {
			width: "1440px",
			height: "auto",
			fontFamily: "Raleway !important",
			display: "flex",
			justifyContent: "center",
			background: "url(" + Bg3 + ")",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundPosition: "center",
			margin: "0px!important",
			paddingTop: "20px",
			paddingBottom: "50px",
			position: "relative",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
			},
		},
		mainContainer: {
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			padding: "30px",
			margin: "50px 0px 50px 0px",
			[theme.breakpoints.down("sm")]: {
				padding: "10px",
				paddingBottom: "20px",
			},
		},
		headerContent: {
			display: "flex",
			justifyContent: "center",
			marginBottom: "30px",
		},
		heading2: {
			marginTop: "30px!important",
			position: "relative",
			textTransform: "none",
			fontFamily: "raleway!important",
			fontWeight: "700!important",
			fontSize: "30px!important",
			display: "flex",
			justifyContent: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "25px!important",
				textAlign: "center",
				marginTop: "15px!important",
				marginBottom: "10px!important",
			},
		},
		bottom1: {
			padding: "0px!important",
			[theme.breakpoints.down("sm")]: {
				// marginTop: '5px!important'
			},
		},
		Icons: {
			position: "relative",
			width: "60px",
			height: "60px",
			background: "#fff",
			borderRadius: "50%",
			border: "1px solid",
			borderLeftColor: "#00c6ff",
			borderRightColor: "#0072ff",
			borderBottomColor: "#0072ff",
			borderTopColor: "#00c6ff",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"&:hover": {
				background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
				transform: "rotate(360deg)",
				transition: "transform .5s ease-in-out",
				border: "0px",
			},
		},
		Img: {
			position: "relative",
			zIndex: "99999",
			margin: "15px",
			width: "30px",
			height: "30px",
			[theme.breakpoints.down("sm")]: {
				margin: "0px",
				width: "20px",
				height: "20px",
			},
		},
	};
});

function Features() {
	const classes = useStyles();
	const theme = useTheme();
	console.log(theme);

	const [iconHover1, setIconHover1] = useState(DesignHover);
	const [iconHover2, setIconHover2] = useState(CatalogHover);
	const [iconHover3, setIconHover3] = useState(FilterHover);
	const [iconHover4, setIconHover4] = useState(OrderHover);
	const [iconHover5, setIconHover5] = useState(WishlistHover);
	const [iconHover6, setIconHover6] = useState(Pages);

	return (
		<Grid container className={classes.FeaturesMain}>
			<Container maxWidth='lg'>
				<Box className={classes.headerContent} flexDirection='column'>
					<Typography
						variant='h4'
						className={classes.heading2}
						data-aos='zoom-in'
						data-aos-duration='2000'>
						<b> AWESOME FEATURES</b>
					</Typography>
					<Box style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
						<img src={lineShape} alt='line'></img>
					</Box>
				</Box>
				{/* <Box style={{width: '1200px',height: 'auto',margin: '20px'}}> */}
				<Grid container className={classes.mainContainer} spacing={2}>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in-right'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover1(IconHover1);
							}}
							onMouseLeave={() => {
								setIconHover1(DesignHover);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover1} alt='Design' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontSize: "18px",
										fontFamily: "Raleway",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									CUSTOMIZED DESIGN
								</Typography>
								<Typography style={{ color: "#444", fontSize: "14px", fontFamily: "Raleway" }}>
									Customize your eCommerce Website design with Featured Products, Offers, Brands,
									Categories and many more.{" "}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover2(IconHover2);
							}}
							onMouseLeave={() => {
								setIconHover2(CatalogHover);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover2} alt='Catalog' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontFamily: "Raleway",
										fontSize: "18px",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									RICH CATALOGUES
								</Typography>
								<Typography style={{ color: "#403939", fontSize: "14px", fontFamily: "Raleway " }}>
									Offering rich and multi-variant Product Catalogue designs with Ratings, Reviews,
									Wish List etc.{" "}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in-left'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover3(IconHover3);
							}}
							onMouseLeave={() => {
								setIconHover3(FilterHover);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover3} alt='Filter' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontFamily: "Raleway",
										fontSize: "18px",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									GET WHAT YOU WANT
								</Typography>
								<Typography style={{ color: "#403939", fontSize: "14px", fontFamily: "Raleway" }}>
									Filter through many categories of products and browse through many brands and get
									you are looking for.{" "}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in-right'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover4(IconHover4);
							}}
							onMouseLeave={() => {
								setIconHover4(OrderHover);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover4} alt='Order' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontFamily: "Raleway",
										fontSize: "18px",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									MANAGE ORDERS
								</Typography>
								<Typography style={{ color: "#403939", fontSize: "14px", fontFamily: "Raleway" }}>
									Place and Track your online orders in real-time. Get notified on every order
									updates.
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover5(IconHover5);
							}}
							onMouseLeave={() => {
								setIconHover5(WishlistHover);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover5} alt='Wishlist' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontFamily: "Raleway",
										fontSize: "18px",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									MANAGE WISHLIST
								</Typography>
								<Typography style={{ color: "#403939", fontSize: "14px", fontFamily: "Raleway" }}>
									Add Items to your Wish List and Order anytime from the items you liked.
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.bottom1}
						lg={4}
						md={4}
						xs={12}
						sm={6}
						data-aos='zoom-in-left'
						data-aos-duration='2000'>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							onMouseOver={() => {
								setIconHover6(IconHover6);
							}}
							onMouseLeave={() => {
								setIconHover6(Pages);
							}}>
							<Box className={classes.Icons}>
								<img src={iconHover6} alt='Pages' className={classes.Img} />
							</Box>
						</Grid>
						<Grid item xs={10} sm={10} md={10} lg={10}>
							<Box style={{ padding: "0px 20px 0px 20px" }}>
								<Typography
									style={{
										color: "#222",
										fontFamily: "Raleway",
										fontSize: "18px",
										fontWeight: "700",
										marginBottom: "5px",
									}}>
									MANAGE PAGES
								</Typography>
								<Typography style={{ color: "#403939", fontSize: "14px", fontFamily: "Raleway" }}>
									Create new Pages and publish on your eCommerce Website on the go.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				{/* </Box> */}
			</Container>
		</Grid>
	);
}

export default Features;
