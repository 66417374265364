import { MdSupportAgent, MdOutlineAccountCircle } from "react-icons/md";
import { Box, Button } from "@material-ui/core";
import React from "react";
import ContactModal from "./ContactModal";

function Section13(props) {
	const [modalState, setModalState] = React.useState({ open: false });
	return (
		<Box sx={{ pb: 8, pt: 4 }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					sx={{
						background: "#37A3DB",
						width: { xs: "100%", sm: "300px" },
						height: "60px",
						borderRadius: 0,
					}}
					startIcon={<MdOutlineAccountCircle />}
					onClick={() => setModalState({ open: true, title: "Create Village" })}
				>
					CREATE ViILLAGE
				</Button>
				<Button
					variant="contained"
					sx={{
						background: "#3E3D3D",
						width: { xs: "100%", sm: "300px" },
						height: "60px",
						borderRadius: 0,
						ml: { xs: 0, sm: 3 },
						mt: { xs: 4, sm: 0 },
					}}
					startIcon={<MdSupportAgent />}
					onClick={() => setModalState({ open: true, title: "Contact Us" })}
				>
					CONTACT US
				</Button>
			</Box>
			{modalState?.open && (
				<ContactModal
					data={modalState}
					handleClose={() => setModalState({ open: false })}
				/>
			)}
		</Box>
	);
}

export default Section13;
