import React from "react";
import { withRouter } from "react-router";
import GetStarted from "./getStarted";
import Information from "./information";
import SetStore from "./setstore";
import { SubmitFormApi } from "./ApiServices/ApiServices";
import axios from "axios";
import SuccessModal from "../SuccessModal";
import { CircularProgress, Backdrop } from "@material-ui/core";
import SnackbarComponent from "../SnackbarComponent";

function MainFormComponent(params) {
	const [rendorComp, setRendorComp] = React.useState("Page1");
	const [state, setState] = React.useState({});
	const [ipData, setIpData] = React.useState({
		mcc: "",
	});

	const [messageType, setMessageType] = React.useState("");
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "info",
		message: "",
	});

	const handleRandorComp = (page) => {
		setRendorComp(page);
	};
	console.log("state", state);
	const handleChange = (event) => {
		event && event.preventDefault && event.preventDefault();
		console.log(event.target);
		setState((prevState) => ({
			...prevState,
			[event.target.name]:
				event.target.name === "email" ? event.target.value.replace(" ", "") : event.target.value,
		}));
	};

	const handleIpData = (e) => {
		console.log(e.target.name, e.target.value);
		setIpData((prevIpData) => ({
			...prevIpData,
			[e.target.name]:
				e.target.name === "domain" ? e.target.value.replace(" ", "").toLowerCase() : e.target.value,
		}));
	};

	console.log("====================================");
	console.log("state", state);
	console.log("====================================");
	// --------- API Call Start ------------- //

	const ApiCall = (e) => {
		setMessageType("Loading");
		e.preventDefault();
		SubmitFormApi({
			businessName: ipData.businessName,
			domain: ipData.domain,
			email: state.email,
			firstName: state.firstName,
			lastName: state.lastName,
			mobile: state.mobile,
			mcc: ipData.mcc,
			experience: state?.experience,
			businessType: state?.businessType,
			website: state?.website,
			storePanelUrl: [
				`${ipData.domain?.replace(" ", "_")?.toLowerCase()}.cartnshop.com`,
				`sp-${ipData.domain?.replace(" ", "_")?.toLowerCase()}.cartnshop.com`,
			],
			password: state.password,
			userType: "2",
		})
			.then((res) => {
				setMessageType("");
				console.log(res);
				if (res) {
					setMessageType("Success");
				}
			})
			.catch((e) => {
				setMessageType("Error");
				setSnackbar({
					open: true,
					message: e.message,
					severity: "error",
				});
			});
	};

	const GetGeoInfo = React.useCallback(() => {
		axios.get("https://ipapi.co/json/").then((response) => {
			let tempdata = response.data;
			setIpData((prevState) => ({
				...prevState,
				...tempdata,
			}));
		});
	}, []);

	const handleClose = () => {
		setMessageType("");
		params.history.push("/");
	};

	const handleCloseSnackbar = () => {
		setSnackbar({
			open: false,
			severity: "error",
			message: "",
		});
	};
	React.useEffect(() => {
		GetGeoInfo();
	}, [GetGeoInfo]);

	console.log(ipData);
	// --------- API Call End ------------- //

	const Component = () => {
		switch (rendorComp) {
			case "Page1":
				return (
					<GetStarted
						key={"1"}
						handleRandorComp={handleRandorComp}
						handleChange={handleChange}
						value={state}
						mccode={ipData.country_calling_code}
					/>
				);
			case "Page2":
				return (
					<SetStore
						key={"2"}
						handleRandorComp={handleRandorComp}
						handleChange={handleIpData}
						value={state}
						data={ipData}
					/>
				);
			case "Page3":
				return (
					<Information
						key={"3"}
						handleRandorComp={handleRandorComp}
						handleChange={handleChange}
						handleClick={ApiCall}
					/>
				);
			default:
				return (
					<GetStarted
						key={"5"}
						handleRandorComp={handleRandorComp}
						handleChange={handleChange}
						value={state}
					/>
				);
		}
	};

	const showMessage = () => {
		switch (messageType) {
			case "Success":
				return <SuccessModal open={messageType === "Success"} handleClose={handleClose} />;
			case "Loading":
				return (
					<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
						<CircularProgress />
					</Backdrop>
				);
			case "Error":
				return <SnackbarComponent {...snackbar} handleClose={handleCloseSnackbar} />;
			default:
				break;
		}
	};
	return (
		<React.Fragment>
			{Component()}
			{showMessage()}
		</React.Fragment>
	);
}
export default withRouter(MainFormComponent);
