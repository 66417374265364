import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

// import cartnshop from "../assets/cartnshop.png";
import cartnshop from "../assets/cartnshopLogo.png";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { ListItemText } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-scroll";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../index.css";

const logoHeight = window?.innerWidth < 1025 ? "51px" : "61px";

const useStyles = makeStyles((theme) => {
	return {
		logo: {
			transition: "0.6s",
			position: "absolute",
			top: window.innerWidth < 1025 ? "20px" : "15px",
			left: "5px",
			bottom: "0px",
			fontSize: "",
			right: "0px",
			// width: "200px",
			height: logoHeight,
			objectFit: "contain",
			cursor: "pointer",
			// [theme.breakpoints.down("sm")]: {
			// 	position: "absolute",
			// 	top: "7px",
			// 	left: "10px",
			// 	// width: "100%",
			// 	height: "45px",
			// },
		},
		MainAppBar: {
			// background: 'linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0',
			position: "fixed",
			zIndex: "1700",
			top: "0px",
			bottom: "auto",
			right: "10px",
			left: "0px",
			width: "100%",
			height: "51px",
			transition: "0.6s",
			display: "flex",
			background: "",
			padding: "50px 10px",
			fontSize: "25px",
			fontFamily: "raleway!important",
			fontWeight: "600!important",
			justifyContent: "flex-end",
			alignItems: "center",
			marginBottom: "100px!important",
			// '&:hover': {
			//     background: 'transparent!important'
			// },
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				height: "50px",
			},
		},
		mainNavbar: {
			display: "flex",
			listStyle: "none",
			justifyContent: "flex-end",
			alignItems: "center!important",
			marginBottom: "0px",
			marginRight: "20px",
		},
		navButton: {
			background: "transparent!important",
			border: "0px!important",
			color: "white !important",
			margin: window?.innerWidth < 1025 ? "2.5px" : "5px",
			textDecoration: "none",
			padding: window?.innerWidth < 1025 ? "2.5px" : "5px",
			fontSize: "16px!important",
			cursor: "pointer",
			[theme.breakpoints.down("lg")]: {
				fontSize: "12px!important",
			},
		},
		drawerIconContainer: {
			width: "50px",
			height: "50px",
			display: "flex",
			justifyContent: "top",
			alignItems: "center",
			position: "absolute!important",
			top: "5px",
			right: "10px",
			marginLeft: "auto",
			"&:hover": {
				backgroundColor: "transparent",
			},
		},
		listContainer: {
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0!important",
			width: "200px!important",
			height: "auto",
		},
		listItemText: {
			...theme.typography.tab,
			color: "white",
			capacity: 0.7,
		},
		listItemtextselected: {
			capacity: 1,
		},
		drawerIcon: {
			height: "70px",
			width: "70px",
			// position: 'absolute',
		},
	};
});

function NavBar(props) {
	if (window.location.pathname === "/") {
		window.onscroll = function () {
			scrollFunction();
		};
		function scrollFunction() {
			if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
				try {
					document.getElementById("navbar").style.padding = "30px 10px";
					// document.getElementById("logo").style.width = "150px";
					document.getElementById("logo").style.height = window.innerWidth < 1025 ? "41px" : "41px";
					document.getElementById("logo").style.position = "absolute";
					document.getElementById("logo").style.top = "8px";
					document.getElementById("navbar").style.background =
						"linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0";
				} catch {
					return null;
				}
			} else {
				try {
					document.getElementById("navbar").style.padding = "50px 10px";
					document.getElementById("logo").style.fontSize = "25px";
					document.getElementById("navbar").style.background = "transparent";
					// document.getElementById("logo").style.width = "200px";
					document.getElementById("logo").style.height = logoHeight;
					document.getElementById("logo").style.position = "absolute";
					document.getElementById("logo").style.top = window.innerWidth < 1025 ? "20px" : "15px";
				} catch {
					return null;
				}
			}
		}
	}

	const classes = useStyles();
	const theme = useTheme();

	const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const [openDrawer, setOpenDrawer] = useState(false);

	const [value, setValue] = useState(0);

	const drawer = (
		<>
			<SwipeableDrawer
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				open={openDrawer}
				anchor='Left'
				onclose={() => setOpenDrawer(false)}
				onopen={() => setOpenDrawer(true)}
				onClick={() => {
					setOpenDrawer(false);
				}}
				sx={{ zIndex: window.scrollY > 10 ? 0 : 10000 }}>
				<List disablePadding className={classes.listContainer}>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(0);
						}}
						divider
						button
						selected={value === 0}>
						<ListItemText
							className={
								value === 0
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							Home
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(0);
						}}
						divider
						button
						// onClick={() => {props.history.push('/')}}
						selected={value === 0}
						style={{ marginTop: "15px" }}>
						<ListItemText
							className={
								value === 0
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='home'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Home
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						divider
						button
						onClick={() => {
							props.history.push("/setstore");
						}}>
						<ListItemText
							className={
								value === 2
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='about'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								About
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem divider button>
						<ListItemText
							className={
								value === 3
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='feature'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Features
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='screenshot'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Screenshots
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='pricing'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Pricing
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='faq'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								FAQ
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='client'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Clients
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='contact'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								Contact
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button>
						<ListItemText
							className={
								value === 4
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							<Link
								to='download'
								onClick={() => {
									setOpenDrawer(false);
								}}>
								App Download
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem
						onClick={() => {
							setOpenDrawer(false);
							setValue(3);
						}}
						divider
						button
						onClick={() => {
							props.history.push("/getstarted");
						}}>
						<ListItemText
							className={
								value === 5
									? [classes.listItemText, classes.listItemtextselected]
									: classes.listItemText
							}
							disableTypography>
							Get Started
						</ListItemText>
					</ListItem>
				</List>
			</SwipeableDrawer>
			<IconButton
				className={classes.drawerIconContainer}
				onClick={() => setOpenDrawer(!openDrawer)}
				disableRipple>
				<MenuIcon className={classes.drawerIcon} />
			</IconButton>
		</>
	);
	const tabs = (
		<React.Fragment>
			<ul className={classes.mainNavbar}>
				{/* <Button variant="outlined" size="small" style={{textTransform: 'none'}} className={classes.navButton1} activeClass="active" label="Home" onClick={() => {window.scroll(0,0)} }>Home</Button> */}
				<li className={classes.navButton}>
					<Link
						className='disabled'
						style={{ padding: "8px", textDecoration: "none" }}
						to='home'
						spy={true}>
						Home
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='about' spy={true}>
						About
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='feature' spy={true}>
						Features
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='screenshot' spy={true}>
						Screenshots
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='pricing' spy={true}>
						Pricing
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='faq' spy={true}>
						FAQ
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='client' spy={true}>
						Clients
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link style={{ padding: "8px", textDecoration: "none" }} to='contact' spy={true}>
						Contact
					</Link>
				</li>
				<li className={classes.navButton}>
					<Link
						style={{ padding: "8px", textDecoration: "none" }}
						onClick={() => {
							props.history.push("/GetStarted");
						}}
						spy={true}>
						Get Started For Free
					</Link>
				</li>
			</ul>
		</React.Fragment>
	);

	return (
		<div id='home'>
			<React.Fragment>
				<Box className={classes.MainAppBar} id='navbar'>
					<img
						className={classes.logo}
						id='logo'
						onClick={() => {
							window.scroll(0, 0);
						}}
						src={cartnshop}
						alt='logo'
					/>
					<div>{matches ? drawer : tabs}</div>
				</Box>
			</React.Fragment>
		</div>
	);
}
export default withRouter(NavBar);
