import { MdSupportAgent, MdOutlineAccountCircle } from "react-icons/md";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";
import ContactModal from "./ContactModal";

function Section1(props) {
	const [modalState, setModalState] = React.useState({ open: false });

	return (
		<Box
			sx={{
				backgroundImage: `url(${assetUrls.homeBGImg})`,
				height: { xs: 825, sm: 840, md: 1024 },
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				padding: { xs: "0 0 0 20px", sm: "0 40px 0 40px", md: "0 40px 0 60px" },
			}}
		>
			<Box
				sx={{
					maxWidth: "1280px",
				}}
			>
				<Typography
					color="#FFF"
					sx={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: 700,
						fontSize: { xs: "40px", sm: "75px", md: "100px", lg: "125.463px" },
						// lineHeight: "157px",
					}}
				>
					DIGITAL VILLAGES
				</Typography>
				<Typography
					color="#FFF"
					variant="h1"
					sx={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontSize: { xs: "24px", sm: "34px", md: "45px", lg: "55px" },
						color: "#FFFFFF",
						opacity: 0.77,
						order: 1,
						alignSelf: "stretch",
					}}
				>
					CartnShop Villages as drivers of Trade and Innovation in Rural Areas
				</Typography>
				<Box
					sx={{
						mt: 7,
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
					}}
				>
					<Button
						variant="contained"
						sx={{
							background: "#37A3DB",
							width: "250px",
							height: "50px",
							borderRadius: 0,
						}}
						startIcon={<MdOutlineAccountCircle />}
						onClick={() => setModalState({ open: true, title: "Create Village" })}
					>
						CREATE ViILLAGE
					</Button>
					<Button
						variant="contained"
						sx={{
							background: "#3E3D3D",
							width: "250px",
							height: "50px",
							borderRadius: 0,
							ml: { xs: 0, sm: 3 },
							mt: { xs: 2, sm: 0 },
						}}
						startIcon={<MdSupportAgent />}
						onClick={() => setModalState({ open: true, title: "Contact Us" })}
					>
						CONTACT US
					</Button>
				</Box>
			</Box>
			{modalState?.open && (
				<ContactModal
					data={modalState}
					handleClose={() => setModalState({ open: false })}
				/>
			)}
		</Box>
	);
}

export default Section1;
