import MainFormComponent from "./components/GettingStarted/MainFormComponent";
import StartingPage from "./components/startingPage";
import AboutUs from "../src/components/about";
import DigitalVillage from "./components/DigitalVillage";

const Routes = [
	{
		path: "/",
		label: "component",
		component: StartingPage,
	},
	{
		path: "/about",
		label: "component",
		component: AboutUs,
	},
	{
		path: "/getstarted",
		label: "component",
		component: MainFormComponent,
	},
	{
		path: "/digital-village",
		label: "component",
		component: DigitalVillage,
	},
];

export default Routes;
