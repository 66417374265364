import { executePost } from "../../../Services/iwant-rest-http-post";
import { executeGet } from "../../../Services/iwant-rest-generic";

const SubmitFormApi = (requestBody) => {
	let url =
		"https://gateway.cartnshop.com/services/merchant-service/api/merchant";
	let actionCode = "ACTION_REGISTER_MERCHANT";
	let payload = requestBody;
	let method = "POST";
	return executePost(url, actionCode, payload, method, true);
};

const GetDataApi = () => {
	let url = "http:///services/merchant-service/api/merchant";
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export { SubmitFormApi, GetDataApi };
