import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section7(props) {
	const headingStyle = { color: "#000", fontWeight: 700 };
	const contentStyle = { color: "#484848", marginBottom: 15, marginTop: 5 };

	return (
		<Box>
			<Grid
				container
				spacing={{ xs: 3, sm: 3, md: 5 }}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
				>
					<Box
						sx={{
							height: "100%",
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							src={assetUrls.img3}
							alt=""
							style={{ height: 250, width: 250, objectFit: "contain" }}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={8}
				>
					<Typography
						sx={{
							display: "flex",
							flexDirection: "column",
							fontSize: { xs: "12px", sm: "14px", md: "16px" },
						}}
						variant="body1"
					>
						<span style={headingStyle}>
							Many rural communities in Kenya have been caught in a vicious circle of
							decline.
						</span>
						<span style={contentStyle}>
							Outmigration and negative demographic trends have their origins in poor
							access to public services and markets, inadequate public infrastructure,
							and sub-standard living conditions in comparison to urban and Sub-urban
							areas.
						</span>

						<span style={headingStyle}>
							Digital technologies have the capacity to radically transform the
							disadvantages that rural areas face.
						</span>
						<span style={contentStyle}>
							in terms of distance and low population density by permitting virtual
							communication, improving access to services, farm management and
							decision-making, increasing overall efficiency and agricultural
							productivity with a wide range of potential markets.
						</span>
						<span style={headingStyle}>
							Digital gap between rural and urban areas persists.
						</span>
						<span style={contentStyle}>
							Alongside access to mobile and broadband Internet, the digital divide is
							much wider if a lack of digital skills and human capacities is
							considered.
						</span>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section7;
