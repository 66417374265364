import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Typography, Grid, Container } from "@material-ui/core";
import lineShape from "../assets/lineShape.png";

// import boozeappLogo from "../assets/boozeappLogo.png"
// import gazebo from "../assets/gazebo.jpg"
// import Redoak from "../assets/Redoak.png"
// import foodbite from "../assets/foodbite.png"
// import logoBanner from "../assets/logoBanner.jpg"
import PestmaticLogo from "../assets/PestmaticLogo.jpg";
// import richCafe from "../assets/richCafe.png"
// import SMB from "../assets/SMB.png"
// import SK from "../assets/SK.png"
// import Vettel from "../assets/Vettel.png"
import posta from "../assets/logo/posta.png";
import county from "../assets/logo/county.png";
import equity from "../assets/logo/equity.png";
import safaricom from "../assets/logo/safaricom.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Autoplay } from "swiper/core";
// import Swiper core and required modules

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const jsonObj = [
	// {img:boozeappLogo},
	// {img:gazebo},
	// {img:Redoak},
	// {img:foodbite},
	// {img:logoBanner},
	// {img:richCafe},
	// {img:SMB},
	// {img:SK},
	// {img:Vettel},
	// {img:boozeappLogo},
	// {img:gazebo},
	{ img: county },
	{ img: posta },
	{ img: PestmaticLogo },
	{ img: equity },
	{ img: safaricom },
];

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			height: "auto",
			background: "white",
			position: "relative",
			padding: "20px 0 20px 0",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				display: "flex",
				position: "relative",
			},
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				width: "100%",
				height: "400px",
				position: "relative",
			},
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				width: "300px",
				height: "300px",
				position: "relative",
			},
		},
		heading: {
			marginTop: "30px!important",
			fontFamily: "raleway!important",
			fontWeight: "700!important",
			fontSize: "30px!important",
			position: "relative",
			textTransform: "none",
			display: "flex",
			justifyContent: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "25px!important",
				textAlign: "center",
				marginTop: "15px!important",
				marginBottom: "10px!important",
			},
		},
		header: {
			marginBottom: "50px",
		},
		BoxSwiper: {
			height: "400px",
			width: "100%",
			[theme.breakpoints.down("md")]: {
				height: "300px",
				width: "100%",
			},
			[theme.breakpoints.down("sm")]: {
				height: "250px",
				width: "100%",
			},
		},
	};
});

export default function Clients() {
	const classes = useStyles();
	const swiperConfig = {
		slidesPerView: 5,
		spaceBetween: 30,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		// pagination:{
		//     clickable: true
		// },
		className: "mySwiperNew",
	};

	return (
		<Grid
			container
			className={classes.mainContainer}
		>
			<Container>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Box
						className={classes.header}
						flexDirection="column"
					>
						<Typography
							className={classes.heading}
							variant="h4"
						>
							<b>OUR CLIENTS</b>
						</Typography>
						<Box
							style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
						>
							<img
								src={lineShape}
								alt="line"
							></img>
						</Box>
					</Box>
				</Grid>
				<Box
					className={classes.BoxSwiper}
					style={{}}
				>
					<Swiper {...swiperConfig}>
						{jsonObj.map((data, index) => (
							<SwiperSlide>
								<Box style={{ paddingTop: "26px" }}>
									<img
										alt=""
										style={{
											height: "200px",
											width: "200px",
											objectFit: "contain",
											borderRadius: "8px",
										}}
										src={data.img}
									/>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
				</Box>
			</Container>
		</Grid>
	);
}
