import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Button, Container, Grid, TextField, Typography } from "@material-ui/core";

import cartnshop from "../../assets/cartnshop.png";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => {
	return {
		jcc: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			color: "white",
			height: "auto",
			padding: "0px!important",
		},
		MainBox: {
			height: "761px",
			width: "896px",
			background: "#04B4FF",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: "20px",
			[theme.breakpoints.down("sm")]: {
				width: "400px",
				margin: "5px!important",
			},
		},
		header: {
			fontFamily: "Raleway !important",
			fontSize: "40px!important",
			[theme.breakpoints.down("sm")]: {
				fontSize: "25px!important",
			},
		},
		logo: {
			width: "71px",
			height: "70px",
			cursor: "pointer",
			top: "54px",
			left: "57px",
			[theme.breakpoints.down("sm")]: {
				width: "35px",
				height: "35px",
				top: "10px",
				left: "10px",
			},
		},
		textMedia: {
			width: "388px",
			minHeight: "42px",
			padding: "0px!important",
			"& .MuiInputBase-root": {
				height: "42px",
				color: "white!important",
				borderRadius: "12px!important",
			},
			"& .MuiInputLabel-root": {
				color: "white",
				fontWeight: "700",
			},
			display: "flex",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				width: "200px",
			},
		},
		text1Media: {
			width: "179px",
			minHeight: "42px",
			"& .MuiInputBase-root": {
				height: "42px",
				color: "white!important",
				borderRadius: "12px!important",
			},
			"& .MuiInputLabel-root": {
				color: "white",
				fontWeight: "700",
			},
			margin: "0px!important",
			padding: "0px!important",
			[theme.breakpoints.down("sm")]: {
				width: "200px",
				marginBottom: "18px!important",
			},
		},
		text2Media: {
			width: "117px",
			minHeight: "42px",
			"& .MuiInputBase-root": {
				height: "42px",
				color: "white!important",
				borderRadius: "12px!important",
			},
			"& .MuiInputLabel-root": {
				color: "white",
				fontWeight: "700",
			},
			[theme.breakpoints.down("sm")]: {
				width: "200px",
				marginBottom: "18px!important",
			},
		},
		textMedia1: {
			width: "388px",
			height: "42px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "space-between",
			margin: "0px!important",
			padding: "0px!important",
			[theme.breakpoints.down("sm")]: {
				width: "250px",
				minHeight: "125px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
		},
		textMedia2: {
			width: "388px",
			minHeight: "42px",
			display: "flex",
			justifyContent: "space-between",
			// alignItems: "space-between",
			[theme.breakpoints.down("sm")]: {
				width: "250px",
				minHeight: "200px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
		},
		nextButton: {
			width: "85px",
			minHeight: "42px",
			marginTop: "25px",
			fontFamily: "Raleway !important",
			borderRadius: "12px!important",
			"&:hover": {
				background: "#04B4FF!important",
			},
		},
		BackButton: {
			width: "85px",
			minHeight: "42px",
			fontFamily: "Raleway !important",
			margin: "5px!important",
			borderRadius: "12px!important",
			"&:hover": {
				background: "#04B4FF!important",
			},
		},
	};
});

function SetStore(props) {
	const { handleRandorComp, handleChange, data } = props;
	const [state, setState] = React.useState({});

	// const handleChange = (event) => {
	//     setState((prevState) => ({
	//         ...prevState,
	//         [event.target.name]: event.target.value
	//     }))
	// }
	console.log(state);
	const classes = useStyles();

	return (
		<container maxWidth='lg'>
			<img
				className={classes.logo}
				onClick={() => {
					props.history.push("/");
				}}
				src={cartnshop}
				alt='logo'
			/>
			<Container
				maxWidth='lg'
				style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<Box className={classes.MainBox}>
					{/* <Grid container  style={{display: 'flex', justifyContent: "space-between", alignItems: 'space-between', margin: '0px',padding: '0px'}}> */}
					<form
						className={classes.formClass}
						onSubmit={(event) => {
							event.preventDefault();
							handleRandorComp("Page3");
						}}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ marginBottom: "38px" }}>
							<Typography className={classes.header}>
								<b>Set Up Your Store</b>
							</Typography>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ marginBottom: "18px", padding: "0px!important" }}>
							<TextField
								label='Business Name'
								variant='filled'
								value={data.businessName}
								className={classes.textMedia}
								name='businessName'
								required
								autoComplete='off'
								onChange={handleChange}
							/>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ marginBottom: "18px", padding: "0px!important" }}>
							<TextField
								label='Domain'
								variant='filled'
								className={classes.textMedia}
								name='domain'
								value={data.domain}
								required
								onChange={handleChange}
								autoComplete='off'
								inputProps={{ minLength: 3 }}
							/>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ padding: "0px!important", marginBottom: "21px" }}>
							<Box className={classes.textMedia1} style={{ padding: "0px!important" }}>
								<TextField
									label='Country'
									variant='filled'
									value={data.country_name}
									className={classes.text1Media}
									autoComplete='off'
									name='country_name'
									required
									onChange={handleChange}
								/>
								<TextField
									label='Currency'
									variant='filled'
									value={data.currency && data.currency}
									className={classes.text1Media}
									autoComplete='off'
									name='currency'
									required
									onChange={handleChange}
								/>
							</Box>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ marginBottom: "13px" }}>
							<Typography variant='h6'>Address</Typography>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={classes.jcc}
							style={{ marginBottom: "46px" }}>
							<Box className={classes.textMedia2}>
								<TextField
									label='City'
									variant='filled'
									className={classes.text2Media}
									value={data.city && data.city}
									autoComplete='off'
									name='city'
									required
									onChange={handleChange}
								/>
								<TextField
									label='State'
									variant='filled'
									value={data.region && data.region}
									className={classes.text2Media}
									autoComplete='off'
									name='region'
									required
									onChange={handleChange}
								/>
								<TextField
									label='Postel Code'
									type='number'
									variant='filled'
									value={data.postal && data.postal}
									className={classes.text2Media}
									autoComplete='off'
									name='postal'
									required
									onChange={handleChange}
								/>
							</Box>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
							<Button
								variant='contained'
								style={{ textTransform: "none" }}
								// onClick={() => props.history.push('/information')}
								onClick={() => {
									handleRandorComp("Page1");
								}}
								className={classes.BackButton}>
								Back
							</Button>
							<Button
								variant='contained'
								style={{ textTransform: "none" }}
								// onClick={() => props.history.push('/information')}
								type='sumbit'
								className={classes.nextButton}>
								Next
							</Button>
						</Grid>
					</form>
					{/* </Grid> */}
				</Box>
			</Container>
		</container>
	);
}
export default withRouter(SetStore);
