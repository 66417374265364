import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Typography,
	Grid,
	Container,
	Box,
	Card,
	CardContent,
	IconButton,
	useMediaQuery,
} from "@material-ui/core";
import { Collapse } from "@material-ui/core";

import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import lineShape from "../assets/lineShape.png";
import faqImg from "../assets/faqImg.png";

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			height: "auto",
			background: "white",
			position: "relative",
			// top: '5330px',
			// left: '0px',
			// right: '0px',
			// bottom: '0px',
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				display: "flex",
				justifyContent: "center",
				position: "relative",
			},
		},
		FaqContent: {
			width: "970px",
			height: "100%",
			padding: "50px 50px 50px 50px",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				padding: "10px",
				paddingBottom: "20px",
			},
		},
		heading: {
			width: "100%",
			height: "auto",
			fontFamily: "raleway!important",
			fontWeight: "700!important",
			fontSize: "30px!important",
			position: "relative",
			textTransform: "none",
			display: "flex",
			justifyContent: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "20px!important",
				textAlign: "center",
			},
		},
		headerContent: {
			display: "flex",
			justifyContent: "center",
			marginBottom: "50px",
			[theme.breakpoints.down("md")]: {
				marginBottom: "0px",
			},
		},
		faqImg: {
			display: "flex",
			alignItems: "center",
			width: "555px",
			height: "294px",
			paddingRight: "50px",
			[theme.breakpoints.down("lg")]: {
				width: "340px",
				height: "180px",
			},
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		},
		faqQuestions: {
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "space-between",
			justifyContent: "space-between",
		},
		panel1: {
			width: "100%",
			borderLeft: "2px solid",
			borderColor: "#00c6ff!important",
			color: "#444",
			margin: "5px",
		},
		faq: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			fontFamily: "raleway!important",
			fontSize: "18px!important",
			// fontSize: matches ? '15px' : '20px',
			fontWeight: "600!important",
			color: "#444",
		},
		faqAnswer: {
			fontFamily: "raleway!important",
			fontSize: "12px!important",
			fontWeight: "600!important",
			lineHeight: "24px!important",
			color: "#444",
		},
	};
});

export default function Faq() {
	const classes = useStyles();
	const theme = useTheme();

	const [expanded, setExpanded] = React.useState(false);
	const [expanded2, setExpanded2] = React.useState(false);
	const [expanded3, setExpanded3] = React.useState(false);
	const [iconAdd, setIconAdd] = React.useState(true);
	const [iconAdd2, setIconAdd2] = React.useState(true);
	const [iconAdd3, setIconAdd3] = React.useState(true);

	const handleExpandClick = () => {
		setExpanded(!expanded);
		setIconAdd(!iconAdd);
	};
	const handleExpandClick2 = () => {
		setExpanded2(!expanded2);
		setIconAdd2(!iconAdd2);
	};
	const handleExpandClick3 = () => {
		setExpanded3(!expanded3);
		setIconAdd3(!iconAdd3);
	};

	return (
		<Grid container className={classes.mainContainer}>
			<Container>
				<Grid container className={classes.FaqContent}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Box className={classes.headerContent} flexDirection='column'>
							<Typography
								variant='h4'
								className={classes.heading}
								data-aos='zoom-in'
								data-aos-duration='2000'>
								<b> FREQUENTLY ASKED QUESTIONS</b>
							</Typography>
							<Box style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
								<img src={lineShape} alt='line'></img>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={0} sm={0} md={6} lg={6} data-aos='fade-right'>
						{/* <Box > */}
						<img className={classes.faqImg} src={faqImg} alt='FAQ IMG'></img>
						{/* </Box> */}
					</Grid>
					<Grid item xs={10} sm={12} md={6} lg={6}>
						<Box className={classes.faqQuestions}>
							<Card className={classes.panel1}>
								<CardContent>
									<Typography className={classes.faq}>
										1. What is Cart "n" Shop?
										<IconButton style={{ color: "#00c6ff" }}>
											{iconAdd ? (
												<ControlPointIcon onClick={handleExpandClick} />
											) : (
												<RemoveCircleIcon onClick={handleExpandClick} />
											)}
										</IconButton>
									</Typography>
								</CardContent>

								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography variant='body1' className={classes.faqAnswer}>
											Cart "n" Shop is a Cloud Based Product which allows any Retail/Enterprise
											Business to expand their sales channels online to attract new customers,
											inquiries and sales orders. The Product offer a solutions to deliver an Omni
											Channel experience to customer with centralized view & control across multiple
											sales channel & Marketing Channels.
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
							<Card className={classes.panel1}>
								<CardContent>
									<Typography className={classes.faq} variant='h6'>
										2. How to use Cart "n" Shop?
										<IconButton style={{ color: "#00c6ff" }} onClick={handleExpandClick2}>
											{iconAdd2 ? (
												<ControlPointIcon onClick={handleExpandClick2} />
											) : (
												<RemoveCircleIcon onClick={handleExpandClick2} />
											)}
										</IconButton>
									</Typography>
								</CardContent>

								<Collapse in={expanded2} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography variant='body1' className={classes.faqAnswer}>
											You can build & Manage your own Shopping Portal or Mobile Apps using Cart "n"
											Shop without coding Efforts or IT Skills. Cart "n" Shop offers you a toolkit
											for Processing & Tracking Orders as well as various Marketing tools for
											Customer Enggagement, Promotions & Campaigns.
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
							<Card className={classes.panel1}>
								<CardContent>
									<Typography className={classes.faq}>
										3. Why Cart "n" Shop?
										<IconButton style={{ color: "#00c6ff" }} onClick={handleExpandClick3}>
											{iconAdd3 ? (
												<ControlPointIcon onClick={handleExpandClick3} />
											) : (
												<RemoveCircleIcon onClick={handleExpandClick3} />
											)}
										</IconButton>
									</Typography>
								</CardContent>

								<Collapse in={expanded3} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography variant='body1' className={classes.faqAnswer}>
											Cart "n" Shop Allows you to Quickly roll out your Products, Promotions, Offers
											via your own website or mobile application with minimal Cost. This means you
											get higher ROI on Marketing & Sales expenses using the Cart "n" Shop
											infrastructure.{" "}
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
}
