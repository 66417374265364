// import { createMuiTheme } from "@material-ui/core-v4/styles";
import { createTheme } from "@material-ui/core";

const arcBlue = "#0B72B9";
const arcOrange = "#FFBA60";
const arcGrey = "#868686";

const theme = createTheme({
	breakpoints: {
		keys: ["xs", "sm", "md", "lg", "xl"],
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	palette: {
		common: {
			blue: arcBlue,
			orange: arcOrange,
		},
		primary: {
			main: arcBlue,
		},
		secondary: {
			main: arcOrange,
		},
	},
	typography: {
		tab: {
			fontFamily: "Raleway",
			textTransform: "none",
			fontWeight: 700,
			color: "white",
			fontSize: "1rem",
		},
		estimate: {
			fontFamily: "Pacifico",
			fontSize: "1rem",
			textTransform: "none",
			color: "white",
		},
		h1: {
			fontFamily: "Raleway",
			fontWeight: 700,
			fontSize: "2.5rem",
			color: arcBlue,
			lineHeight: 1.5,
		},
		h3: {
			fontFamily: "Pacifico",
			fontSize: "2.5rem",
			color: arcBlue,
		},
		h4: {
			fontFamily: "Raleway",
			fontSize: "1.75rem",
			color: arcBlue,
			fontWeight: 700,
		},
		h6: {
			fontWeight: 500,
			fontFamily: "Raleway",
			color: arcBlue,
			lineHeight: 1,
		},
		subtitle1: {
			fontSize: "1.25rem",
			fontFamily: "Raleway",
			fontWeight: 300,
			color: arcGrey,
		},
		subtitle2: {
			color: "white",
			fontFamily: "Raleway",
			fontWeight: 300,
			fontSize: "1.25rem",
		},
		body1: {
			fontSize: "1.25rem",
			fontFamily: "Raleway !important",
			color: arcGrey,
			fontWeight: 300,
		},
		caption: {
			fontSize: "1rem",
			fontWeight: 300,
			color: arcGrey,
		},
		learnButton: {
			borderColor: arcBlue,
			borderWidth: 2,
			textTransform: "none",
			color: arcBlue,
			borderRadius: 50,
			fontFamily: "Roboto",
			fontWeight: "bold",
		},
	},
	overrides: {
		MuiInputLabel: {
			root: {
				color: arcBlue,
				fontSize: "1rem",
			},
		},
		MuiInput: {
			root: {
				color: arcGrey,
				fontWeight: 300,
			},
			underline: {
				"&:before": {
					borderBottom: `2px solid ${arcBlue}`,
				},
				"&:hover:not($disabled):not($focused):not($error):before": {
					borderBottom: `2px solid ${arcBlue}`,
				},
			},
		},
	},
});

export default theme;
