import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Container, Typography, Box, Button } from "@material-ui/core";
import AppMockup from "../assets/AppMockup.png";
import Android from "../assets/android.png";
import Apple from "../assets/apple.png";

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			fontFamily: "raleway !important",
			height: "auto",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0",
			position: "relative",
			display: "flex",
			justifyContent: "center",
			padding: "20px 0px 20px 0px",
			[theme.breakpoints.down("md")]: {
				display: "flex",
				position: "relative",
			},
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				width: "100%",
				height: "400px",
				position: "relative",
			},
		},
		DownloadContainer: {
			width: "100%",
			height: "100%",
			// padding: '50px 0px',
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		AppDownload: {
			fontFamily: "raleway !important",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		ContentContainer: {
			fontFamily: "raleway !important",
			display: "flex",
			justifyContent: "center",
			textAlign: "center",
			alignItems: "center",
			color: "white",
			padding: "0px 15px 0px 15px",
		},
		AppButton: {
			maxWidth: "455px!important",
			maxHeight: "64px",
			fontFamily: "raleway !important",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			marginTop: "50px!important",
			color: "white",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				marginTop: "0px",
				flexDirection: "column!important",
				alignItems: "center",
			},
		},
		button1: {
			border: "2px solid!important",
			borderRadius: "30px!important",
			textTransform: "none!important",
			margin: "5px!important",
			background: "transparent!important",
			color: "white!important",
			fontFamily: "raleway !important",
			fontWeight: "600!important",
			padding: "10px 20px 10px 20px!important",
			lineHeight: "1.3!important",
			textAlign: "left",
			"&:hover": {
				background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)!important",
				border: "0px!important",
			},
		},
	};
});

export default function AppDownload() {
	const classes = useStyles();
	// const theme = useTheme();

	return (
		<Grid container className={classes.mainContainer}>
			<Container>
				<Grid container className={classes.DownloadContainer}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<Box className={classes.ContentContainer} flexDirection='column'>
							<Typography
								variant='h4'
								style={{ fontFamily: "raleway", fontWeight: "700", marginBottom: "10px" }}>
								<b>Register now on Cart "n" Shop Today</b>
							</Typography>
							<Typography
								variant='body1'
								style={{
									fontFamily: "raleway",
									fontWeight: "600",
									fontSize: "14px",
									lineHeight: "24px",
								}}>
								Try our Cart "n" Shop Applications for your Android Mobile or iPhone.
							</Typography>
							<Grid className={classes.AppButton}>
								<Button
									onClick={() =>
										window.open(
											"https://play.google.com/store/apps/details?id=com.iwant.marketplace.business.cartnshop"
										)
									}
									variant='outlined'
									className={classes.button1}>
									{" "}
									<img
										style={{ width: "35px", height: "35px", marginRight: "10px" }}
										src={Android}
										alt='android'
									/>
									<p style={{ marginBottom: "0px", fontFamily: "raleway", fontWeight: "600" }}>
										{" "}
										available on <br />
										<span style={{ fontSize: "18px" }}>Google Store</span>
									</p>
								</Button>
								<Button
									onClick={() =>
										window.open("https://apps.apple.com/gb/app/iwant-cartnshop/id1319782379")
									}
									variant='outlined'
									className={classes.button1}>
									<img
										style={{ width: "35px", height: "35px", marginRight: "10px" }}
										src={Apple}
										alt='apple'
									/>{" "}
									<p style={{ marginBottom: "0px", fontFamily: "raleway", fontWeight: "600" }}>
										{" "}
										available on <br />
										<span style={{ fontSize: "18px" }}>Apple Store</span>
									</p>
								</Button>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} data-aos='fade-left' data-aos-duration='1000'>
						<Box className={classes.AppDownload}>
							<img src={AppMockup} alt='App Download'></img>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
}
