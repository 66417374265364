import { Box, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section2(props) {
	return (
		<Box
			sx={{
				backgroundImage: `url(${assetUrls.homeBGImg1})`,
				height: 233,
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box sx={{ width: "85%" }}>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: 700,
						fontSize: { xs: "24px", sm: "44px", md: "50px" },
						textAlign: "center",
						letterSpacing: "-0.01em",
						color: "#FEFEFE",
					}}
				>
					Cartnshop Marketplace!
				</Typography>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: 700,
						fontSize: { xs: "12px", sm: "18px", md: "20px" },
						lineHeight: "120%",
						alignItems: "center",
						color: "#F7F7F7",
					}}
				>
					A digital village and market place concept to promote digital trade and
					innovations in rural communities enabling economic livelihoods, individual
					well being and social cohesion, a county-led initiative, user-centered,
					holistic digital ecosystem approach for digital village development,
					allowing the local stakeholders to access the markets and digital channels
					for their product.
				</Typography>
			</Box>
		</Box>
	);
}

export default Section2;
