import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

function Section3(props) {
	return (
		<Box sx={{ px: { xs: 0, sm: 0, md: 0, lg: 5 } }}>
			<Grid
				container
				spacing={{ xs: 0, sm: 2 }}
			>
				<Grid
					item
					xs={12}
					sm={4}
				>
					<Box
						sx={{
							background: "#37A3DB",
							p: { xs: 2, sm: 2, md: 4 },
							height: "100%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSstyle: "normal",
								fontWeight: 700,
								fontSize: { xs: "22px", md: "28.482px" },
								lineHeight: { xs: "26px", md: "34px" },

								color: "#FFFFFF",
							}}
						>
							Online payments made easier and safer. security is assured
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
				>
					<Box
						sx={{
							p: { xs: 2, sm: 2, md: 4 },
						}}
					>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontStyle: "normal",
								fontWeight: 700,
								fontSize: { xs: "18px", md: "22px" },
								lineHeight: "27px",
								color: "#3A3A3A",
							}}
						>
							User-friendly interface
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontStyle: "normal",
								fontWeight: 400,
								fontSize: { xs: "14px", md: "17.1735px" },
								lineHeight: "21px",
								color: "#484848",
								mt: 1,
							}}
						>
							This makes it easy for even first time users to complete transactions
							with confidence and ease.
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
				>
					<Box
						sx={{
							p: { xs: 2, sm: 2, md: 4 },
						}}
					>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontStyle: "normal",
								fontWeight: 700,
								fontSize: { xs: "18px", md: "22px" },
								lineHeight: "27px",
								color: "#3A3A3A",
							}}
						>
							Safety
						</Typography>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontStyle: "normal",
								fontWeight: 400,
								fontSize: { xs: "14px", md: "17.1735px" },
								lineHeight: "21px",
								color: "#484848",
								mt: 1,
							}}
						>
							The platform offers a range of features and tools that enables smooth
							transactions and help ensure the safety of sensitive financial
							information
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section3;
