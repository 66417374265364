import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Container,
	Typography,
	Grid,
	Card,
	TextField,
	Button,
	IconButton,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import SendIcon from "@material-ui/icons/Send";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import imgWaveAlt from "../assets/imgWaveAlt.png";
import ThankYouModal from "./DigitalVillage/thankYouModal";

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			height: "auto",
			display: "flex",
			justifyContent: "center",
			position: "relative",
			padding: "0px 0 20px 0",
			margin: "0px!important",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
		},
		header: {
			width: "100%",
			height: "100%",
			position: "relative",
			padding: "0px!important",
			margin: "0px!important",
			objectFit: "contain",
		},
		contactForm: {
			marginTop: "30px",
		},
		sendButton: {
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			borderRadius: "100px!important",
			color: "white",
			textAlign: "center",
			marginTop: "20px!important",
		},
		AddressIcon: {
			border: "1px solid!important",
			borderColor: "white!important",
			borderRadius: "50px!important",
		},
		footer: {
			display: "flex",
			justifyContent: "center",
			padding: "20px",
			// borderTop: '1px solid #00c6ff',
			// '&hover:' :{
			//     borderTop: '1px solid white',
			// }
		},
		footerIconButton: {
			border: "1px solid white!important",
			borderRadius: "50px!important",
			marginRight: "10px!important",
			color: "white!important",
		},
		footerIconButton1: {
			border: "1px solid white!important",
			borderRadius: "50px!important",
			marginRight: "10px!important",
			backgroundColor: "white!important",
			"&:hover": {
				background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
				border: "0px!important",
			},
		},
		footerLink: {
			"&:hover": {
				color: "black!important",
				textDecoration: "none",
				border: "0px!important",
			},
		},
		imgWave: {
			width: "100%",
			height: "100%",
			display: "flex",
			objectFit: "contain",
		},
	};
});

export default function ContactArea() {
	const classes = useStyles();
	const params = window.location.search;
	const redirectUri = "https://cartnshop.com";

	const [state, setState] = useState([]);
	const handleChange = (event) => {
		setState((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const [openThanksModal, setOpenThanksModal] = React.useState(false);

	React.useEffect(() => {
		if (params && params === "?formSubmitted") {
			setOpenThanksModal(true);
			setTimeout(() => {
				setOpenThanksModal(false);
			}, 3000);
		}
	}, [params]);

	return (
		<React.Fragment>
			<Grid
				container
				className={classes.mainContainer}
			>
				<Grid className={classes.header}>
					<img
						src={imgWaveAlt}
						alt="img-wave"
						className={classes.imgWave}
					/>
				</Grid>
				<Container>
					<Grid
						container
						style={{ margin: "50px 0px 50px 0px", background: "#2193f3" }}
						data-aos="zoom-in-up"
						data-aos-duration="1000"
					>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
						>
							<Card
								xs={12}
								sm={6}
								md={6}
								lg={6}
								style={{
									padding: "30px",
									paddingTop: "50px",
									borderRadius: "0px",
									height: "100%",
								}}
							>
								<Typography
									variant="h4"
									style={{
										fontFamily: "raleway",
										fontWeight: "700!important",
										fontSize: "30px!important",
										color: "#222!important",
									}}
								>
									<b>Get In Touch</b>
								</Typography>
								<form
									action="https://formsubmit.co/nandicountymarketplace@gmail.com"
									method="POST"
								>
									<input
										type="hidden"
										name="_next"
										value={redirectUri}
									/>
									<input
										type="hidden"
										name="_redirect"
										value={redirectUri}
									/>
									<input
										type="hidden"
										name="_captcha"
										value="false"
									/>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											required
											size="small"
											label="Your Name"
											variant="standard"
											id="email"
											name="name"
											type="name"
											style={{ width: "100%" }}
											onChange={handleChange}
										/>
										{/* <Typography sx={{ mb: 0.5 }}>Your Name</Typography>
										<input
											placeholder="Enter Your Full Name"
											type="text"
											name="name"
											required
											style={{
												width: "100%",
												padding: "7px 10px",
												borderRadius: "0px",
												border: "0 solid #999",
												borderWidth: "0 0 1px 0",
											}}
										/> */}
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											className={classes.textMedia}
											required
											size="small"
											label="Your Email"
											variant="standard"
											id="email"
											name="email"
											type="email"
											style={{ width: "100%" }}
										/>
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											className={classes.textMedia}
											required
											size="small"
											label="Your Subject"
											variant="standard"
											id="subject"
											name="subject"
											type="text"
											style={{ width: "100%" }}
										/>
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											className={classes.textMedia1}
											required
											multiline
											rows={3}
											size="small"
											label="Message"
											variant="standard"
											id="Message"
											name="message"
											type="text"
											style={{ width: "100%" }}
										/>
									</Grid>
									<Button
										className={classes.sendButton}
										type="submit"
										variant="contained"
										label="SEND MESSAGE"
									>
										SEND MESSAGE
									</Button>
								</form>
							</Card>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
						>
							<Card
								xs={12}
								sm={6}
								md={6}
								lg={6}
								style={{
									backgroundColor: "#2193f3",
									padding: "50px",
									color: "white",
									borderRadius: "0px",
									height: "100%",
								}}
							>
								<Typography
									variant="h4"
									style={{
										marginBottom: "25px",
										fontFamily: "raleway",
										fontWeight: "700",
										fontSize: "30px",
									}}
								>
									<b>Connect with us!</b>
								</Typography>
								<Typography
									variant="body1"
									style={{
										fontFamily: "raleway",
										fontSize: "14px",
										fontWeight: "600",
										lineHeight: "24px",
										marginBottom: "15px",
									}}
								>
									We help MSMEs build digital store fronts Cartnshop enables African SMES
									to set up online stores Quickly and affordably. It is an end to end
									e-store enabler engine and infrastructure, with a B2B and B2C channels
									and value chain incorporating payment gateways, delivery and
									fulfillment centers, marketing and Market place cross listings and
									business management capabilities .{" "}
								</Typography>
								<Typography
									variant="body1"
									style={{
										fontFamily: "raleway",
										fontSize: "14px",
										fontWeight: "600",
										lineHeight: "24px",
										marginBottom: "5px",
									}}
								>
									reach us on
								</Typography>
								<Grid item>
									{/* <IconButton className={classes.AddressIcon}></IconButton> */}
									<Typography
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "10px",
											fontFamily: "raleway",
											fontSize: "18px",
											fontWeight: "600",
											lineHeight: "24px",
										}}
									>
										<IconButton
											size="small"
											href="https://cartnshop.com/"
											className={classes.footerIconButton}
										>
											<PhoneIcon fontSize="small" />
										</IconButton>{" "}
										+254757987239
									</Typography>
									<Typography
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "10px",
											fontFamily: "raleway",
											fontSize: "18px",
											fontWeight: "600",
											lineHeight: "24px",
										}}
									>
										<IconButton
											size="small"
											// href='https://cartnshop.com/'
											href="mailto:info@cartnshop.com"
											className={classes.footerIconButton}
										>
											<SendIcon fontSize="small" />
										</IconButton>{" "}
										info@cartnshop.com
									</Typography>
									<Typography
										style={{
											display: "flex",
											marginBottom: "10px",
											fontFamily: "raleway",
											fontSize: "18px",
											fontWeight: "600",
											lineHeight: "24px",
										}}
									>
										<LocationOnIcon
											fontSize="medium"
											style={{
												border: "1px solid white",
												padding: "5px",
												width: "32px",
												height: "32px",
												borderRadius: "50px",
												marginRight: "10px",
												color: "white",
											}}
										/>{" "}
										Golf park ,Racecourse ,next to KK security ,last block ,Ngong Road
									</Typography>
								</Grid>
							</Card>
						</Grid>
					</Grid>
					<Grid
						container
						className={classes.footer}
						direction="column"
					>
						<Grid
							item
							style={{
								display: "flex",
								justifyContent: "center",
								marginBottom: "20px",
							}}
						>
							<IconButton
								size="small"
								className={classes.footerIconButton1}
							>
								<FacebookIcon />
							</IconButton>
							<IconButton
								size="small"
								className={classes.footerIconButton1}
							>
								<InstagramIcon />
							</IconButton>
							<IconButton
								size="small"
								className={classes.footerIconButton1}
							>
								<TwitterIcon />
							</IconButton>
							<IconButton
								size="small"
								className={classes.footerIconButton1}
							>
								<LinkedInIcon />
							</IconButton>
						</Grid>
						<Grid
							item
							style={{
								display: "flex",
								justifyContent: "center",
								color: "white",
								textAlign: "center",
							}}
						>
							<Typography
								style={{
									fontFamily: "raleway",
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
								}}
							>
								Copyright @ 2021{" "}
								<a
									className={classes.footerLink}
									href="wwwhotgroup.co.ke"
									style={{ color: "white" }}
								>
									House Of Technolgy Ltd.
								</a>{" "}
								all right reserved.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{openThanksModal && (
				<ThankYouModal
					open={openThanksModal}
					handleClose={() => setOpenThanksModal(false)}
				/>
			)}
		</React.Fragment>
	);
}
