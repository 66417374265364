const assetUrls = {
	homeBGImg:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/homeBGImg.webp",
	homeBGImg1:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/homeBGImg1.webp",
	img1:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/img1.png",
	img2:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/img2.png",
	liIcon:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/liIcon.png",
	img3:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/image3.png",
	cowBanner:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/cowBanner.png",
	banner1:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/banner1.png",
	DVISec8:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/DVI.png",
	tractorBanner:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/tractoreBanner.webp",
	posterImg1:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/posterImg1.png",
	posterImage3:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/posterImage3.png",
	posterImage2:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/posterImage2.png",
	assessmentTool:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/assessmentTool.png",
	sliderImg1:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/sliderImg1.png",
	sliderImg2:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/sliderImg2.png",
	sliderImg3:
		"https://s3.ap-southeast-1.amazonaws.com/cartnshop.com/digitalvillage/sliderImg3.png",
};

export default assetUrls;
