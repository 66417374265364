import { Box, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

const textArray = [
	"Communities in rural areas to use innovative solutions to improve their resilience, building on local strenghths and opportunities",
	"They rely on participatory approach to develop and implement their strategy to improve their economic, social and/or environmental conditions, in particular by mobilizing solutions offered by digital technologies",
	"CartnShop Villages can handle over 2 million merchants concurrently.",
	"Cooperation and Alliances with other villages and inter-coun ty trade using digital channels",
];

function Section5(props) {
	return (
		<Box>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 700,
					fontSize: { xs: "26px", sm: "44px", md: "50px" },
					textAlign: "center",
					letterSpacing: "-0.01em",
					color: "#37A3DB",
				}}
			>
				CartnShop Digital Villages
			</Typography>
			<Box sx={{ mt: 1 }}>
				{textArray?.map((ele, index) => (
					<Box
						key={index}
						sx={{ display: "flex", justifyContent: "flex-start", mt: 1.5 }}
					>
						<Box
							sx={{
								height: 25,
								width: 25,
								minWidth: 25,
								background: "#37A3DB",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: "50%",
							}}
						>
							<img
								src={assetUrls.liIcon}
								alt=""
								style={{ height: 20, width: 20, objectFit: "contain" }}
							/>
						</Box>
						<Box>
							<Typography
								sx={{
									ml: 1,
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: 300,
									fontSize: { xs: "14px", sm: "16px", md: "20px" },
									lineHeight: { xs: "22px", sm: "26px", md: "29px" },
									color: "#000000",
								}}
							>
								{ele}
							</Typography>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
}

export default Section5;
