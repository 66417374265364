import { Box, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section8(props) {
	const { DVISec8 } = assetUrls;
	return (
		<Box>
			<Typography
				sx={{
					ml: 1,
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: { xs: "22px", sm: "28px", md: "32px", lg: "34px", xl: "36px" },
					textAlign: "center",
					color: "#000000",
					marginBottom: { xs: 1, md: 2 },
				}}
			>
				The Cartnshop Digital Villages Initiative (DVI)
			</Typography>
			<Typography
				sx={{
					ml: 1,

					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: { xs: "12px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
					textAlign: "center",
					color: "#000000",
				}}
			>
				A digitally-enabled rural Transformation platform to revitalize rural
				communities through the uptake of Digital Market Places, ICTs and access the
				digital services.
			</Typography>
			<Box sx={{ mt: 2 }}>
				<img
					src={DVISec8}
					alt="three - tier"
					style={{ width: "100%", height: "100%", objectFit: "contain" }}
				/>
			</Box>
		</Box>
	);
}

export default Section8;
