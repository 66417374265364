import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section11(props) {
	return (
		<Box>
			<Typography
				sx={{
					ml: 1,
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: { xs: "22px", sm: "28px", md: "32px", lg: "34px", xl: "36px" },
					textAlign: "center",
					color: "#000000",
				}}
			>
				Digital Village Readiness Assessment Tool
			</Typography>
			<Typography
				sx={{
					ml: 1,
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: { xs: "12px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
					textAlign: "center",
					color: "#000000",
				}}
			>
				8 criteria across 3 dimensions to analyze and select village to engage in
				the DVI
			</Typography>
			<Grid
				container
				spacing={2}
				sx={{ mt: 3 }}
				direction={{ xs: "column-reverse", md: "row" }}
			>
				<Grid
					item
					xs={12}
					md={4}
				>
					<Box sx={{ background: "#F1F1F1", p: 2, m: { xs: 0, md: 1 } }}>
						<Typography
							sx={{ fontWeight: 600, color: "#44536A", p: 1, textAlign: "center" }}
						>
							DVI Readiness Assessment Tool
						</Typography>
						<Typography
							sx={{
								display: "flex",
								flexDirection: "column",
								fontSize: { xs: "14px", sm: "16px", md: "18px" },
								color: "#000",
							}}
						>
							<span>The tool is based on 8 criteria</span>
							<span>that look at 3 dimensions</span>
							<ul>
								<li>Digital ecosystem</li>
								<li>Strategic context</li>
								<li>Leadership and governance of a rural community</li>
							</ul>
							<span>
								The analysis of each criteria determines the level of maturity: seed,
								grow, sprout or blossoming.
							</span>
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
				>
					<Box>
						<Box sx={{ height: { xs: "auto", md: "400px" }, width: "100%" }}>
							<img
								src={assetUrls?.assessmentTool}
								alt=""
								style={{ height: "100%", width: "100%", objectFit: "contain" }}
							/>
						</Box>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "space-around",
								fontSize: { xs: "14px", sm: "16px", md: "18px" },
								mt: { xs: 2, md: 0 },
							}}
						>
							<Typography
								sx={{
									color: "#000",
									fontWeight: 500,
									fontSize: { xs: "14px", sm: "16px", md: "18px" },
								}}
							>
								Digital Skills
							</Typography>
							<Typography
								sx={{
									fontSize: { xs: "14px", sm: "16px", md: "18px" },
									color: "#000",
									fontWeight: 500,
								}}
							>
								Stakeholder engagement
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section11;
