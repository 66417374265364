import { Box, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section12(props) {
	return (
		<Box
			sx={{
				backgroundImage: `url(${assetUrls.homeBGImg1})`,
				height: 233,
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					width: "85%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "center",
					pb: { xs: 2, md: 1, lg: 0 },
					height: "100%",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: 700,
						fontSize: { xs: "36px", sm: "62px", md: "88px", lg: "102px" },
						letterSpacing: "-0.01em",
						color: "#FEFEFE",
					}}
				>
					DIGITAL VILLAGES
				</Typography>
			</Box>
		</Box>
	);
}

export default Section12;
