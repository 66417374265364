import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section6(props) {
	return (
		<Box>
			<Grid
				container
				spacing={{ xs: 3, sm: 3, md: 5 }}
				direction={{ xs: "column-reverse", md: "row" }}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
				>
					<Box
						sx={{
							height: "100%",
							width: "100%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "flex-start",
								height: {
									xs: "280px",
									sm: "180px",
									md: "250px",
								},
								px: { xs: 0, sm: 5, md: 0 },
							}}
						>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: 900,
									fontSize: { xs: "28px", sm: "30px", md: "34px" },
									lineHeight: { xs: "32px", sm: "36px", md: "38px" },
									color: "#37A3DB",
								}}
							>
								The digital divide is nowhere more evident than in rural areas
							</Typography>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: 400,
									fontSize: "14.2109px",
									lineHeight: { xs: "22px", sm: "24px" },
									color: "#484848",
									pr: { xs: 0, sm: 2, md: 10 },
								}}
							>
								The potential of digital technologies to address local challenges and
								improve rural livelihoods
							</Typography>
							<Button
								sx={{
									borderRadius: 0,
									background: "#37A3DB",
									color: "#FFF",
								}}
								disableRipple={true}
							>
								Learn More
							</Button>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
				>
					<img
						src={assetUrls.img2}
						alt=""
						style={{ width: "100%", objectFit: "contain", maxHeight: 400 }}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section6;
