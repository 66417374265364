
import React from "react";
import { useTheme,makeStyles  } from "@material-ui/styles";
import { Grid, Typography,Box } from "@material-ui/core";
import lineShape from "../assets/lineShape.png"

import screenshot1 from "../assets/screenshot1.jpg"
import screenshot2 from "../assets/screenshot2.jpg"
import screenshot3 from "../assets/screenshot3.jpg"
import screenshot4 from "../assets/screenshot4.jpg"
import screenshot5 from "../assets/screenshot5.jpg"
import screenshot6 from "../assets/screenshot6.jpg"
import screenshot7 from "../assets/screenshot7.jpg"
import screenshot8 from "../assets/screenshot8.jpg"

import AOS from "aos"
// import SwiperCore, { Autoplay } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,Autoplay
} from 'swiper/core';
// install Swiper modules
SwiperCore.use([Autoplay, EffectCoverflow]);

const jsonObj = [
    {img:screenshot1},
    {img:screenshot2},
    {img:screenshot3},
    {img:screenshot4},
    {img:screenshot5},
    {img:screenshot6},
    {img:screenshot7},
    {img:screenshot7},
    {img:screenshot8},
]

const useStyles = makeStyles((theme) => {
    return({
        mainContainer: {
            width: '1440px',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0px!important',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                
            }
        },
        headerContent: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '50px'
        },
        heading: {
            marginTop: '30px!important', 
            fontFamily: 'raleway!important',
            fontWeight: '700!important',
            fontSize: '30px!important',
            position: 'relative',
            textTransform: 'none',
            display: 'flex',
            justifyContent: "center",
            background: 'linear-gradient(to right, #00c6ff 0%, #0072ff 100%)',
            WebkitBackgroundClip: 'text!important',
            WebkitTextFillColor: 'transparent!important',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '25px!important',
                textAlign: 'center',
                marginTop: '15px!important',
                marginBottom: '10px!important'
            }
        },
        screenshotMedia: {
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                width: '80%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '80%',
            },[theme.breakpoints.down('xs')]: {
                width: '80%'
            }
        },
        imgScroll: {
            width: 'auto',
            height: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                height: 'auto'
            }
        }

    })
})

function AppsScreenshot(){
    
    const classes = useStyles();
    const theme = useTheme();

    AOS.init();
    const swiperConfig = {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        slidesPerView: "auto",
        [theme.breakpoints.down('sm')]: {
            slidesPerView: "3",
        },
        [theme.breakpoints.down('xs')]: {
            slidesPerView: "1",
        },
        pagination: true,
        autoplay: {
          delay: 3000
        },
        coverflowEffect: {
          rotate: 0,
          stretch: 80,
          depth: 200,
          modifier: 1,
          slideShadows: false
        },
        className: "mySwiper"
      };

    return(
        <Grid container className={classes.mainContainer} direction="column">
            <Box className={classes.headerContent} flexDirection="column" >
                <Typography variant="h4"  className={classes.heading} data-aos="zoom-in" data-aos-duration="2000">
                        <b >APP SCREENSHOT</b>
                </Typography>
                <Box style={{display: 'flex', justifyContent: 'center',marginTop: '5px'}}><img  src={lineShape} alt="line"></img></Box>
            </Box>
            <Box className={classes.screenshotMedia} >
                <Swiper {...swiperConfig}>
                    {
                        jsonObj.map((data, index) => (
                            <SwiperSlide key={index}>
                                <div className={classes.imgScroll}>
                                    <img style={{ height: '100%', width: '100%', objectFit: 'contain' }} src={data.img} alt="screenshots" />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Box>
        </Grid>  
    )
}

export default AppsScreenshot;