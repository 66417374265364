import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import {  Paper} from '@material-ui/core';


export default function SuccessModal({open , handleClose}) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
            heigth:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
      >
           
        <Paper style={{width:'30%' , float:'left', padding:'20px 30px'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column',textAlign:'center'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Thank You
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    We will share your StoreUrl and Login credentials on Registered email Address .
                </Typography>
                <Button variant={'contained'} color='success' onClick={handleClose} style={{marginTop: '20px'}}>
                    Go Back
                </Button>
            </div>
        </Paper>    
      </Modal>
    </div>
  );
}