import { Box, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation } from "swiper";

const contentArray = [
	{
		img: "",
		title: "Preliminary identification of candidate villages :",
		desc:
			"Identify the villages that hold potential  to engage the DVI  rural  transformation process.",
	},
	{
		img: "",
		title: "REU DVI Readiness Assessment Tool:",
		desc:
			"Assess the readiness of the village to engage in the DVI transformation process",
	},
	{
		img: "",
		title: "DVI Roadmap:",
		desc:
			"Engage local stakeholders and rural population to co-design a DVI strategy and to identify a set of interventions that address local challenges",
	},
	{
		img: "",
		title: "DVI transformation:",
		desc: "Implement the selected DVI interventions",
	},
];

function Section9(props) {
	const swiperConfig = {
		loop: true,
		speed: 1000,
		autoplay: {
			delay: 3000,
		},
	};

	const imgArray = [
		assetUrls?.sliderImg1,
		assetUrls?.sliderImg2,
		assetUrls?.sliderImg3,
	];
	return (
		<Box>
			<Typography
				sx={{
					ml: 1,
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: { xs: "22px", sm: "28px", md: "32px", lg: "34px", xl: "36px" },
					textAlign: "center",
					color: "#000000",
					marginBottom: { xs: 1, md: 2 },
				}}
			>
				Pathway to Digital Villages Transformation
			</Typography>
			<Typography
				sx={{
					ml: 1,

					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: { xs: "12px", sm: "16px", md: "18px", lg: "20px", xl: "22px" },
					textAlign: "center",
					color: "#000000",
				}}
			>
				Sustainable pathway to the selection of potential rural areas and their
				transformation
			</Typography>
			<Box sx={{ mt: 2 }}>
				{contentArray?.map((ele, index) => (
					<Box sx={{ mt: 0.5 }}>
						<Typography
							sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" }, color: "#000" }}
						>
							<span style={{ fontWeight: 600 }}>{ele?.title}</span> {ele?.desc}
						</Typography>
					</Box>
				))}
			</Box>
			<Box sx={{ width: "100%", mt: 5 }}>
				<Swiper
					navigation={true}
					modules={[Navigation]}
					className="mySwiperDigitalVillage"
					style={{ paddingBottom: "0px !important" }}
					{...swiperConfig}
					slidesPerView={1}
					spaceBetween={10}
					breakpoints={{
						640: {
							slidesPerView: 1,
							spaceBetween: 10,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 10,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
					}}
				>
					{imgArray?.map((ele, index) => (
						<SwiperSlide
							key={index}
							style={{ boxShadow: "none" }}
						>
							<Box sx={{ boxShadow: "none", height: { xs: "200px", sm: "300px" } }}>
								<img
									src={ele}
									alt=""
									style={{ height: "100%", width: "100%", objectFit: "cover" }}
								/>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>
		</Box>
	);
}

export default Section9;
