import * as React from "react";
import { Box, Button, Typography, Modal } from "@material-ui/core";
import { MdSupportAgent, MdOutlineAccountCircle } from "react-icons/md";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: 600 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 3,
	borderRadius: "12px",
};

export default function ContactModal({ data: { open, title }, handleClose }) {
	const redirectUri = "https://cartnshop.com/digital-village?formSubmitted";

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography
					variant="h4"
					fontWeight={600}
					sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				>
					{title === "Contact Us" ? <MdSupportAgent /> : <MdOutlineAccountCircle />}
					<span style={{ marginLeft: "10px" }}>{title}</span>
				</Typography>
				<Box sx={{ mt: 2 }}>
					<form
						action="https://formsubmit.co/nandicountymarketplace@gmail.com"
						method="POST"
					>
						<input
							type="hidden"
							name="_next"
							value={redirectUri}
						/>
						<input
							type="hidden"
							name="_redirect"
							value={redirectUri}
						/>
						<input
							type="hidden"
							name="_captcha"
							value="false"
						/>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box>
								<Typography sx={{ mb: 0.5 }}>Enter Your Full Name</Typography>
								<input
									placeholder="Enter Your Full Name"
									type="text"
									name="name"
									required
									style={{
										width: "100%",
										padding: "7px 10px",
										borderRadius: "8px",
										border: "1px solid #999",
									}}
								/>
							</Box>
							<Box sx={{ mt: 2 }}>
								<Typography sx={{ mb: 0.5 }}>Enter Your Email</Typography>
								<input
									placeholder="Enter Your Email"
									type="email"
									name="email"
									style={{
										width: "100%",
										padding: "7px 10px",
										borderRadius: "8px",
										border: "1px solid #999",
									}}
									required
								/>
							</Box>
							<Box sx={{ mt: 2 }}>
								<Typography sx={{ mb: 0.5 }}>Write Your Message</Typography>
								<textarea
									rows={3}
									placeholder="Write your message here..."
									type="text"
									name="comment"
									style={{
										width: "100%",
										padding: "7px 10px",
										borderRadius: "8px",
										border: "1px solid #999",
									}}
									required
								/>
							</Box>
						</Box>
						<Box sx={{ width: "100%", textAlign: "right" }}>
							<Button
								sx={{ mt: 3, width: 100 }}
								type="submit"
								component={"button"}
								variant="contained"
							>
								Send
							</Button>
						</Box>
					</form>
				</Box>
			</Box>
		</Modal>
	);
}
