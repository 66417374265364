import React from "react";
import { useTheme, makeStyles } from "@material-ui/styles";
import { Container, Grid, Typography, Box, Card, Button } from "@material-ui/core";

import PricingBg from "../assets/PricingBg.png";
import lineShape from "../assets/lineShape.png";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "1440px",
			height: "auto",
			fontFamily: "Relaway",
			display: "flex",
			justifyContent: "center",
			margin: "0px!important",
			padding: "50px",
			position: "relative",
			background: "url(" + PricingBg + ")",
			[theme.breakpoints.down("sm")]: {
				padding: "30px",
				position: "relative",
			},
		},
		heading: {
			width: "100%",
			height: "auto",
			fontFamily: "Relaway",
			fontWeight: "700!important",
			fontSize: "30px!important",
			position: "relative",
			textTransform: "none",
			display: "flex",
			justifyContent: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "25px!important",
				textAlign: "center",
			},
		},
		headerContent: {
			display: "flex",
			justifyContent: "center",
			marginBottom: "50px",
		},
		typography: {
			fontFamily: "Relaway !important",
		},
		header: {
			display: "flex",
			justifyContent: "center",
			padding: "10px",
			fontFamily: "Relaway",
			color: "#444",
			"&:hover": {
				// background: 'linear-gradient(to right, #00c6ff 0%, #0072ff 100%) ',
				color: "#00c6ff!important",
			},
		},
		header2: {
			display: "flex",
			justifyContent: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			color: "white",
			padding: "10px 0px 10px 0px",
			fontSize: "35px",
			fontFamily: "Relaway",
		},
		body: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			padding: "20px 0px 20px 0px",
		},
		content1: {
			background: "#f1f1f1",
			color: "#222",
			width: "100%",
			height: "100%",
			padding: "10px 0px 10px 0px",
			marginBottom: "10px",
			display: "flex",
			justifyContent: "center",
			fontSize: "16px",
		},
		features: {
			listStyle: "none",
			flexDirection: "column",
			padding: "0px",
			margin: "0px",
			textAlign: "center",
			// fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
		},
		liClass: {
			marginBottom: "10px",
			color: "#444",
		},
		footer: {
			borderTop: "1px solid!important",
			borderColor: "#00c6ff!important",
			padding: "20px 0px 30px 0px",
			display: "flex",
			justifyContent: "center",
		},
		footerbtn: {
			borderRadius: "20px!important",
			fontFamily: "Relaway !important",
			border: "2px solid!important",
			borderColor: "#00c6ff!important",
			color: "black!important",
			padding: "5px 30px 5px 30px!important",
			"&:hover": {
				background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%) ",
				color: "white!important",
				border: "0px!important",
			},
		},
	};
});

function Pricing(props) {
	const classes = useStyles();

	return (
		<Grid container className={classes.mainContainer}>
			{/* <Grid item lg={12} md={12} sm={12} xs={12} direction="column"> */}
			<Container>
				<Box className={classes.headerContent} flexDirection='column'>
					<Typography
						variant='h4'
						className={classes.heading}
						data-aos='zoom-in'
						data-aos-duration='2000'>
						<b> MEMBERSHIP PLAN</b>
					</Typography>
					<Box style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
						<img src={lineShape} alt='line'></img>
					</Box>
				</Box>
				{/* </Grid> */}
				<Grid container spacing={2}>
					{/* -------------------------------------1st section----------------------------------- */}
					<Grid item lg={3} md={3} xs={12} sm={6} data-aos='fade-right' data-aos-duration='1000'>
						<Card>
							<Box className={classes.header}>
								<Typography variant='h6' className={classes.typography}>
									<span style={{ fontSize: "20px" }}>KSH</span>
									<span style={{ fontSize: "35px" }}>
										<b>1499</b>
									</span>
									/<span style={{ fontSize: "15px" }}>Month</span>
								</Typography>
							</Box>
							<Box className={classes.header2}>
								<Typography variant='h6' className={classes.typography}>
									STARTUP
								</Typography>
							</Box>
							<Box className={classes.body}>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Sales Channels & Apps</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>
											<del>Mobile App</del>
										</li>
										<li className={classes.liClass}>Web Portal</li>
										<li className={classes.liClass}>
											<del>Reporting App</del>
										</li>
										<li className={classes.liClass}>
											<del>Integrated Point of Sales</del>
										</li>
										<li className={classes.liClass}>
											<del>Marketplace</del>
										</li>
										<li className={classes.liClass}>Inventory Control</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Integration</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Google Analytics</li>
										<li className={classes.liClass}>Payment Gateway</li>
										<li className={classes.liClass}>
											<del>Shipping Integration</del>
										</li>
										<li className={classes.liClass}>SMS Gateway (ShortCode)</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Products</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Shopping Cart Catalogues</li>
										<li className={classes.liClass}>
											<del>Booking & Reservations</del>
										</li>
										<li className={classes.liClass}>Inquiry Catalogues</li>
										<li className={classes.liClass}>50</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Marketing Toolkit</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Featured Collections</li>
										<li className={classes.liClass}>Product Discounts</li>
										<li className={classes.liClass}>Coupon Code Discounts</li>
										<li className={classes.liClass}>Wishlist Management</li>
										<li className={classes.liClass}>
											<del>Joining & Referral Campaigns</del>
										</li>
										<li className={classes.liClass}>
											<del>Gift Vouchers</del>
										</li>
										<li className={classes.liClass}>
											<del>Loyalty & Rewards</del>
										</li>
										<li className={classes.liClass}>Custom Page Builder</li>
										<li className={classes.liClass}>
											<del>Blogs & Articles</del>
										</li>
										<li className={classes.liClass}>Promotional Communication</li>
									</ul>
								</Box>
							</Box>
							<Box className={classes.footer}>
								<Button
									variant='outlined'
									onClick={() => {
										props?.history.push("/getstarted");
									}}
									className={classes.footerbtn}>
									PURCHASE
								</Button>
							</Box>
						</Card>
					</Grid>

					{/* -------------------------------------2nd section----------------------------------- */}

					<Grid item lg={3} md={3} xs={12} sm={6} data-aos='flip-left' data-aos-duration='1000'>
						<Card>
							<Box className={classes.header}>
								<Typography variant='h6' className={classes.typography}>
									<span style={{ fontSize: "20px" }}>KSH</span>
									<span style={{ fontSize: "35px" }}>
										<b>3999</b>
									</span>
									/<span style={{ fontSize: "15px" }}>Month</span>
								</Typography>
							</Box>
							<Box className={classes.header2}>
								<Typography variant='h6' className={classes.typography}>
									STANDARD
								</Typography>
							</Box>
							<Box className={classes.body}>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Sales Channels & Apps</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>
											<del>Android,ios</del>
										</li>
										<li className={classes.liClass}>Web Portal</li>
										<li className={classes.liClass}>
											<del>Reporting App</del>
										</li>
										<li className={classes.liClass}>
											<del>Integrated Point of Sales</del>
										</li>
										<li className={classes.liClass}>
											<del>Marketplace</del>
										</li>
										<li className={classes.liClass}>Inventory Control</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Integration</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Google Analytics</li>
										<li className={classes.liClass}>Payment Gateway</li>
										<li className={classes.liClass}>Shipping Integration</li>
										<li className={classes.liClass}>SMS Gateway (ShortCode)</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Products</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Shopping Cart Catalogues</li>
										<li className={classes.liClass}>
											<del>Booking & Reservations</del>
										</li>
										<li className={classes.liClass}>Inquiry Catalogues</li>
										<li className={classes.liClass}>100</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Marketing Toolkit</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Featured Collections</li>
										<li className={classes.liClass}>Product Discounts</li>
										<li className={classes.liClass}>Coupon Code Discounts</li>
										<li className={classes.liClass}>Wishlist Management</li>
										<li className={classes.liClass}>
											<del>Joining & Referral Campaigns</del>
										</li>
										<li className={classes.liClass}>
											<del>Gift Vouchers</del>
										</li>
										<li className={classes.liClass}>
											<del>Loyalty & Rewards</del>
										</li>
										<li className={classes.liClass}>Custom Page Builder</li>
										<li className={classes.liClass}>
											<del>Blogs & Articles</del>
										</li>
										<li className={classes.liClass}>Promotional Communication</li>
									</ul>
								</Box>
							</Box>
							<Box className={classes.footer}>
								<Button
									variant='outlined'
									onClick={() => {
										props?.history.push("/getstarted");
									}}
									className={classes.footerbtn}>
									PURCHASE
								</Button>
							</Box>
						</Card>
					</Grid>

					{/* -------------------------------------3rd section----------------------------------- */}

					<Grid item lg={3} md={3} xs={12} sm={6} data-aos='flip-right' data-aos-duration='1000'>
						<Card>
							<Box className={classes.header}>
								<Typography variant='h6' className={classes.typography}>
									<span style={{ fontSize: "20px" }}>KSH</span>
									<span style={{ fontSize: "35px" }}>
										<b>5999</b>
									</span>
									/<span style={{ fontSize: "15px" }}>Month</span>
								</Typography>
							</Box>
							<Box className={classes.header2}>
								<Typography variant='h6' className={classes.typography}>
									PREMIUM
								</Typography>
							</Box>
							<Box className={classes.body}>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Sales Channels & Apps</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Android,ios</li>
										<li className={classes.liClass}>Web Portal</li>
										<li className={classes.liClass}>
											<del>Reporting App</del>
										</li>
										<li className={classes.liClass}>Cart "n" Shop Retail</li>
										<li className={classes.liClass}>
											<del>Marketplace</del>
										</li>
										<li className={classes.liClass}>Inventory Control</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Integration</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Google Analytics</li>
										<li className={classes.liClass}>Payment Gateway</li>
										<li className={classes.liClass}>Shipping Integration</li>
										<li className={classes.liClass}>SMS Gateway (ShortCode)</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Products</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Shopping Cart Catalogues</li>
										<li className={classes.liClass}>
											<del>Booking & Reservations</del>
										</li>
										<li className={classes.liClass}>Inquiry Catalogues</li>
										<li className={classes.liClass}>150</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Marketing Toolkit</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Featured Collections</li>
										<li className={classes.liClass}>Product Discounts</li>
										<li className={classes.liClass}>Coupon Code Discounts</li>
										<li className={classes.liClass}>Wishlist Management</li>
										<li className={classes.liClass}>Joining & Referral Campaigns</li>
										<li className={classes.liClass}>Gift Vouchers</li>
										<li className={classes.liClass}>
											<del>Loyalty & Rewards</del>
										</li>
										<li className={classes.liClass}>Custom Page Builder</li>
										<li className={classes.liClass}>Blogs & Articles</li>
										<li className={classes.liClass}>Promotional Communication</li>
									</ul>
								</Box>
							</Box>
							<Box className={classes.footer}>
								<Button
									variant='outlined'
									onClick={() => {
										console.log("props", props, window);
										props?.history?.push("/getstarted");
									}}
									className={classes.footerbtn}>
									PURCHASE
								</Button>
							</Box>
						</Card>
					</Grid>

					{/* -------------------------------------4th section----------------------------------- */}

					<Grid item lg={3} md={3} xs={12} sm={6} data-aos='fade-left' data-aos-duration='1000'>
						<Card>
							<Box className={classes.header} style={{ marginBottom: "7px" }}>
								<Typography variant='h6' className={classes.typography}>
									<span style={{ fontSize: "30px" }}>
										<b>Inquire Now</b>
									</span>
								</Typography>
							</Box>
							<Box className={classes.header2}>
								<Typography variant='h6' className={classes.typography}>
									LARGE ENTERPRISE
								</Typography>
							</Box>
							<Box className={classes.body}>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Sales Channels & Apps</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Android,ios</li>
										<li className={classes.liClass}>Web Portal</li>
										<li className={classes.liClass}>Reporting App</li>
										<li className={classes.liClass}>Integrated Point of Sales</li>
										<li className={classes.liClass}>Marketplace</li>
										<li className={classes.liClass}>Inventory Control</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Integration</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Google Analytics</li>
										<li className={classes.liClass}>Payment Gateway</li>
										<li className={classes.liClass}>Shipping Integration</li>
										<li className={classes.liClass}>SMS Gateway (ShortCode)</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Products</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Shopping Cart Catalogues</li>
										<li className={classes.liClass}>Booking & Reservations</li>
										<li className={classes.liClass}>Inquiry Catalogues</li>
										<li className={classes.liClass}>unlimited</li>
									</ul>
								</Box>
								<Box className={classes.content1}>
									<Typography className={classes.typography}>
										<b>Marketing Toolkit</b>
									</Typography>
								</Box>
								<Box>
									<ul className={classes.features}>
										<li className={classes.liClass}>Featured Collections</li>
										<li className={classes.liClass}>Product Discounts</li>
										<li className={classes.liClass}>Coupon Code Discounts</li>
										<li className={classes.liClass}>Wishlist Management</li>
										<li className={classes.liClass}>Joining & Referral Campaigns</li>
										<li className={classes.liClass}>Gift Vouchers</li>
										<li className={classes.liClass}>Loyalty & Rewards</li>
										<li className={classes.liClass}>Custom Page Builder</li>
										<li className={classes.liClass}>Blogs & Articles</li>
										<li className={classes.liClass}>Promotional Communication</li>
									</ul>
								</Box>
							</Box>
							<Box className={classes.footer}>
								<Button
									variant='outlined'
									onClick={() => {
										props?.history.push("/getstarted");
									}}
									className={classes.footerbtn}>
									PURCHASE
								</Button>
							</Box>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
}

export default withRouter(Pricing);
