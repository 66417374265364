import { Box, Grid } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

function Section10(props) {
	return (
		<Box>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							pb: { xs: 0, lg: 1 },
						}}
					>
						<Box sx={{ height: { xs: "auto", md: 408 }, width: "100%" }}>
							<img
								src={assetUrls?.posterImg1}
								alt=""
								style={{ height: "100%", width: "100%", objectFit: "contain" }}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: { xs: "center", md: "flex-end" },
						}}
					>
						<Box
							sx={{ width: { xs: "100%", md: 500 }, height: { xs: "auto", md: 310 } }}
						>
							<img
								src={assetUrls?.posterImage2}
								alt=""
								style={{ height: "100%", width: "100%", objectFit: "contain" }}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: { xs: "center", md: "flex-start" },
						}}
					>
						<Box
							sx={{ width: { xs: "100%", md: 500 }, height: { xs: "auto", md: 310 } }}
						>
							<img
								src={assetUrls?.posterImage3}
								alt=""
								style={{ height: "100%", width: "100%", objectFit: "contain" }}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section10;
