import React, { useState } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/styles";
import { Grid, Typography, Card, Box, Button, Container } from "@material-ui/core";
import classess from "./about.module.css";
import { withRouter } from "react-router-dom";

import PCmockup from "../assets/PCmockup.png";
import AppMockup from "../assets/AppMockup.png";
import app from "../assets/app.png";
import payment from "../assets/payment.png";
import inventory from "../assets/inventory.png";
import delivery from "../assets/delivery.png";
import notification from "../assets/notification.png";
import lineShape from "../assets/lineShape.png";
import aboutBg from "../assets/aboutBg.png";
import CardImg1 from "../assets/scrollImg1.png";
import CardImg2 from "../assets/scrollImg2.png";
import CardImg3 from "../assets/scrollImg3.png";
import CardImg4 from "../assets/scrollImg4.png";
import CardImg5 from "../assets/scrollImg5.png";

import { AiOutlineApple, AiOutlineAndroid } from "react-icons/all";

const useStyles = makeStyles((theme) => {
	return {
		//  ------------------- About Section --------------------------------
		aboutSection: {
			width: "1440px",
			height: "auto",
			backgroundColor: "white",
			backgroundImage: "url(" + aboutBg + ")",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundPosition: "center",
			position: "relative",
			padding: "20px 0px 20px 0px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.down("sm")]: {
				height: "800px",
			},
			[theme.breakpoints.down("xs")]: {
				height: "950px",
				paddingBottom: "10px",
			},
		},
		mainContainer: {
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			paddingBottom: "50px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "0px!important",
				paddingBottom: "0px!important",
			},
		},
		headerContent: {
			display: "flex",
			justifyContent: "center",
			textAlign: "center",
			marginBottom: "50px",
		},
		heading: {
			marginBottom: "0",
			position: "relative",
			textTransform: "none",
			display: "flex",
			justifyContent: "center",
			fontFamily: "raleway!important",
			fontWeight: "700!important",
			fontSize: "30px!important",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "0px!important",
				fontSize: "30px!important",
				textAlign: "center",
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: "20px!important",
			},
		},
		aboutText: {
			maxWidth: "570px",
			background: "transparent",
			marginTop: "100px",
			marginBottom: "50px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "0px!important",
				marginBottom: "0px",
			},
		},
		bodyText: {
			fontSize: "14px!important",
			fontWeight: "600px!important",
			color: "#444!important",
			fontFamily: "raleway!important",
			lineHeight: "24px!important",
		},
		phoneImg: {
			paddingRight: "15px",
			paddingLeft: "15px",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		},
		aboutButton: {
			width: "470px",
			height: "64px",
			justifyContent: "space-between",
			marginTop: "40px!important",
			marginBottom: "20px",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "center",
				marginTop: "0px",
				flexDirection: "column!important",
				alignItems: "center",
				marginBottom: "10px",
				width: "300px",
				height: "auto",
			},
		},
		button1: {
			border: "2px solid!important",
			borderRadius: "30px!important",
			textTransform: "none!important",
			margin: "5px!important",
			padding: "10px 20px 10px 20px!important",
			background: "#fff!important",
			fontFamily: "raleway!important",
			fontWeight: "600",
			lineHeight: "1.3!important",
			borderLeftColor: "#00c6ff!important",
			borderRightColor: "#0072ff!important",
			borderBottomColor: "#0072ff!important",
			textAlign: "left",
			borderTopColor: "#00c6ff!important",
			color: "#444!important",
			"&:hover": {
				background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)!important",
				color: "white!important",
				border: "0px!important",
			},
		},
		aboutImage: {
			width: "455px",
			height: "471px",
			background: "transparent",
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		},
		textMainBox: {
			flexDirection: "column",
			paddingRight: "15px",
			paddingLeft: "15px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginTop: "100px",
			// marginLeft: '30px',
			[theme.breakpoints.down("sm")]: {
				marginTop: "10px",
			},
		},

		// ------------------------------ How Does Work Section -------------------
		workSection: {
			width: "1440px",
			height: "auto",
			backgroundColor: "white",
			display: "flex",
			justifyContent: "center",
			position: "relative",
			paddingBottom: "20px",
			paddingTop: "50px",
			paddin: "50PX",
			flexDirection: "column",
			[theme.breakpoints.down("sm")]: {
				position: "relative",
			},
		},
		mainWork: {
			maxWidth: "1440px",
			height: "auto",
			display: "flex",
			justifyContent: "center",
		},
		heading2: {
			paddingBottom: "25px",
			position: "relative",
			textTransform: "none",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			background: "linear-gradient(to right, #00c6ff 0%, #0072ff 100%)",
			WebkitBackgroundClip: "text!important",
			WebkitTextFillColor: "transparent!important",
		},
		middleContent: {
			width: "1440px",
			height: "auto",
			marginBottom: "100px!important",
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column!important",
			},
		},
		middleContent1: {
			width: "1440px",
			height: "auto",
			marginBottom: "10px!important",
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column!important",
			},
		},
		middleImg: {
			width: "450px",
			height: "320px",
			display: "flex",
			alignItems: "center!important",
			justifyContent: "center!important",
			position: "absolute",
			top: "150px",
			[theme.breakpoints.down("lg")]: {
				position: "absolute",
				top: "160px",
			},
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		},
		itemContentStart: {
			padding: "30px",
			[theme.breakpoints.down("md")]: {
				padding: "0px",
				display: "flex",
				justifyContent: "center",
			},
		},
		itemContentEnd: {
			padding: "30px",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				padding: "0px",
				display: "flex",
				justifyContent: "center",
			},
			[theme.breakpoints.down("xs")]: {
				padding: "0px",
				display: "flex",
				justifyContent: "initial",
			},
		},
		Notification: {
			padding: "30px",
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down("md")]: {
				padding: "0px",
				display: "flex",
				justifyContent: "center",
			},
			[theme.breakpoints.down("xs")]: {
				padding: "0px",
				display: "flex",
				justifyContent: "initial",
			},
		},
		Body1: {
			fontFamily: "raleway!important",
			fontSize: "18px!important",
			fontWeight: "600!important",
			color: "#222!important",
			textTransform: "uppercase!important",
		},
		Body2: {
			fontFamily: "raleway!important",
			fontSize: "14px!important",
			fontWeight: "600!important",
			color: "#444!important",
			lineHeight: "24px!important",
		},
	};
});
const BoxCustom = styled(Box)(({ theme }) => ({
	"&::-webkit-scrollbar": {
		width: 0,
	},
	"&::-webkit-scrollbar-track": {
		// boxShadow: `inset 0 0 5px ${theme.palette.primary.light}`,
		borderRadius: 0,
	},
	"&::-webkit-scrollbar-thumb": {
		// backgroundColor:  theme.palette.primary.main,
		borderRadius: 0,
		// '&:hover':{
		//     backgroundColor:  theme.palette.primary.dark
		// }
	},
}));
function AboutUs() {
	const classes = useStyles();
	const theme = useTheme();
	console.log(theme);

	const [hoverCard, setHoverCard] = useState({
		card1: false,
		card2: false,
		card3: false,
		card4: false,
		card5: false,
	});

	const [cardImgHover, setCardImgHover] = useState(CardImg1);

	return (
		<React.Fragment>
			<Grid container className={classes.aboutSection}>
				<Container>
					<Grid item container xs={12} sm={12} md={12} lg={12}>
						<Grid item xs={12} sm={12} md={12} lg={12} data-aos='zoom-in' data-aos-duration='2000'>
							<Typography variant='h4' className={classes.heading}>
								<b>ABOUT OUR APP</b>
							</Typography>
							<Box style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
								<img src={lineShape} alt='line'></img>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Box className={classes.textMainBox}>
								<Grid item className={classes.aboutText}>
									<Typography
										variant='h4'
										style={{
											fontFamily: "raleway",
											fontWeight: "700",
											color: "#222",
											marginBottom: "25px",
										}}>
										we provide native apps to go with the website
									</Typography>
									<Typography variant='body1' className={classes.bodyText}>
										{" "}
										Cart "n" Shop is a digital commerce platform that allows any Retail/Enterprise
										Business to expand their sales channels online to attract new customers, get
										inquiries about products or services, receive sales orders and market online
										beyond their location boundaries. The Product offers technology to deliver an
										Omni Channel experience to customers, with centralized view of the stores &
										control across multiple sales channel & Marketing Channels. Cart "n" Shop Allows
										you to Quickly roll out your Products, Promotions via your own website or mobile
										app.
									</Typography>
								</Grid>
								<Grid item className={classes.aboutButton}>
									<Button
										onClick={() =>
											window.open(
												"https://play.google.com/store/apps/details?id=com.iwant.marketplace.business.cartnshop"
											)
										}
										variant='contained'
										color='primary'
										className={classes.button1}>
										{" "}
										<AiOutlineAndroid style={{ fontSize: "34px", marginRight: "10px" }} />
										<p style={{ marginBottom: "0px", fontFamily: "raleway", fontWeight: "600" }}>
											{" "}
											available on <br />
											<span style={{ fontSize: "18px" }}>Google Store</span>
										</p>
									</Button>
									<Button
										onClick={() =>
											window.open("https://apps.apple.com/gb/app/iwant-cartnshop/id1319782379")
										}
										variant='contained'
										color='primary'
										className={classes.button1}>
										{" "}
										<AiOutlineApple style={{ fontSize: "34px", marginRight: "10px" }} />
										<p style={{ marginBottom: "0px", fontFamily: "raleway", fontWeight: "600" }}>
											{" "}
											available on <br />
											<span style={{ fontSize: "18px" }}>Apple Store</span>
										</p>
									</Button>
								</Grid>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							className={classes.phoneImg}
							data-aos='zoom-in'
							data-aos-duration='4000'>
							<img
								style={{ marginLeft: "100px", width: "440px", height: "414px" }}
								src={AppMockup}
								alt='phone'
							/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{/* -------------------work section -------------------- */}
			<Grid container className={classes.workSection}>
				<Container>
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ display: "flex", justifyContent: "center" }}>
						<Grid item xs={12} sm={12} md={12} lg={12} data-aos='zoom-in' data-aos-duration='2000'>
							<Typography
								variant='h4'
								className={classes.heading}
								data-aos='zoom-in'
								data-aos-duration='2000'>
								<b>HOW DOES IT WORK?</b>
							</Typography>
							<Box style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
								<img src={lineShape} alt='line'></img>
							</Box>
						</Grid>
						<Grid item className={classes.middleContent}>
							<Grid
								item
								lg={4}
								md={4}
								sm={12}
								xs={12}
								data-aos='fade-right'
								data-aos-duration='2000'>
								<Card
									style={{ padding: "20px" }}
									elevation={hoverCard.card5 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card5: true });
										setCardImgHover(CardImg1);
									}}
									onMouseLeave={() => {
										setHoverCard({ card5: false });
									}}>
									<Grid item container>
										<Grid item lg={2} md={3} sm={3} xs={3}>
											<img src={app} alt='app' />
										</Grid>
										<Grid item lg={10} md={9} sm={9} xs={9}>
											<Typography variant='body1' className={classes.Body1}>
												CREATE AND PERSONALIZE APPS
											</Typography>
											<Typography variant='body2' className={classes.Body2}>
												Create your personalized store with eCommerce Website, iOS and Android Apps.
											</Typography>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid
								item
								lg={4}
								md={4}
								sm={12}
								sx={12}
								data-aos='fade-left'
								data-aos-duration='2000'>
								<Card
									style={{ padding: "20px" }}
									elevation={hoverCard.card1 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card1: true });
										setCardImgHover(CardImg2);
									}}
									onMouseLeave={() => {
										setHoverCard({ card1: false });
									}}>
									<Grid item container>
										<Grid item lg={3} md={3} sm={4} xs={3}>
											<img src={payment} alt='app' />
										</Grid>
										<Grid item lg={9} md={9} sm={8} xs={9}>
											<Typography variant='body1' className={classes.Body1}>
												Accept Orders and Payment
											</Typography>
											<Typography variant='body2' className={classes.Body2}>
												Create your personalized store with eCommerce Website, iOS and Android Apps.
											</Typography>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						<Grid item className={classes.middleImg} data-aos='zoom-in' data-aos-duration='2000'>
							<img
								style={{ width: "100%", height: "100%", objectFit: "contain" }}
								src={PCmockup}
								alt='mockup'
							/>
							<Box style={{ height: "170px", width: "260px", top: "40px", position: "absolute" }}>
								<BoxCustom
									style={{
										height: "100%",
										width: "100%",
										overflowY: "scroll",
										position: "relative",
									}}>
									<img alt='' src={cardImgHover} className={classess.scrollImg} />
								</BoxCustom>
							</Box>
						</Grid>
						<Grid item className={classes.middleContent1}>
							<Grid
								item
								lg={4}
								md={4}
								sm={12}
								xs={12}
								data-aos='fade-right'
								data-aos-duration='2000'>
								<Card
									style={{ padding: "20px" }}
									elevation={hoverCard.card2 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card2: true });
										setCardImgHover(CardImg3);
									}}
									onMouseLeave={() => {
										setHoverCard({ card2: false });
									}}>
									<Grid item container>
										<Grid item lg={3} md={3} sm={3} xs={3}>
											<img src={inventory} alt='app' />
										</Grid>
										<Grid item lg={9} md={9} sm={9} xs={9}>
											<Typography variant='body1' className={classes.Body1}>
												Manage Catalogue and Inventory{" "}
											</Typography>
											<Typography variant='body2' className={classes.Body2}>
												Upload your Product Catalogues, Manage dedicated inventory.
											</Typography>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid
								item
								lg={4}
								md={4}
								sm={12}
								sx={12}
								data-aos='fade-left'
								data-aos-duration='2000'>
								<Card
									style={{ padding: "20px" }}
									elevation={hoverCard.card3 ? 5 : 0}
									onMouseOver={() => {
										setHoverCard({ card3: true });
										setCardImgHover(CardImg4);
									}}
									onMouseLeave={() => {
										setHoverCard({ card3: false });
									}}>
									<Grid item container>
										<Grid item lg={3} md={4} sm={4} xs={4}>
											<img src={delivery} alt='app' />
										</Grid>
										<Grid item lg={9} md={8} sm={8} xs={8}>
											<Typography variant='body1' className={classes.Body1}>
												Manage Delivery
											</Typography>
											<Typography variant='body2' className={classes.Body2}>
												Manage Delivery with various logistics platform.
											</Typography>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12} data-aos='zoom-in-up' data-aos-duration='2000'>
							<Card
								style={{ padding: "20px" }}
								elevation={hoverCard.card4 ? 5 : 0}
								onMouseOver={() => {
									setHoverCard({ card4: true });
									setCardImgHover(CardImg5);
								}}
								onMouseLeave={() => {
									setHoverCard({ card4: false });
								}}>
								<Grid item container>
									<Grid item lg={3} md={3} sm={2} xs={4}>
										<img src={notification} alt='notification' />
									</Grid>
									<Grid item lg={9} md={9} sm={10} xs={8}>
										<Typography variant='body1' className={classes.Body1}>
											Promote your Business with SMS, offers and Push Notifications
										</Typography>
										<Typography variant='body2' className={classes.Body2}>
											Share promotional and marketing messages to your customers.
										</Typography>
									</Grid>
								</Grid>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</React.Fragment>
	);
}

export default withRouter(AboutUs);
