import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, Box } from "@material-ui/core-v4";
// import GetStarted from './components/getStarted';
import theme from "./components/theme";
// import SetStore from './components/setstore';
import Main from "./components/Main";

function App() {
	React.useEffect(() => {
		console.log(window.scrollY);
		AOS.init({
			duration: 2000,
		});
		AOS.refresh();
	}, []);

	return (
		// <div>
		//     <MainPage />
		// </div>

		<MuiThemeProvider theme={theme}>
			<BrowserRouter>
				<Box style={{ overflowX: "hidden", fontFamily: "Raleway !important" }}>
					<Main />
				</Box>
			</BrowserRouter>
		</MuiThemeProvider>
	);
}

export default App;

// {/* <Switch>
//         <Route exact path="/" component={MainPage} />
//         <Route exact path="/getfreestarted" component={GetStarted} />
//           <Route exact path="/revolution" component={() => <div>The Revolution</div>} />
//           {/* <Route exact path="/about" component={() => <div>About</div>} />
//           <Route exact path="/login" component={Login} />
//           <Route exact path="/estimate" component={Estimate} /> */}
//         </Switch> */}
