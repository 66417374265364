import React from "react";
import { Box, Container } from "@material-ui/core";
import assetUrls from "./assetUrls";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";
import Section11 from "./Section11";
import Section12 from "./Section12";
import Section13 from "./Section13";
import { withRouter } from "react-router-dom";
import ThankYouModal from "./thankYouModal";

function DigitalVillage(props) {
	const params = window.location.search;
	console.log(params);

	const [openThanksModal, setOpenThanksModal] = React.useState(false);

	React.useEffect(() => {
		if (params && params === "?formSubmitted") {
			setOpenThanksModal(true);
			setTimeout(() => {
				setOpenThanksModal(false);
			}, 3000);
		}
	}, [params]);

	return (
		<Box sx={{ width: "100%" }}>
			{/* <Box sx={{ height: 124, width: "100%" }}></Box> */}
			<Section1 />
			<Container style={{ maxWidth: "1440px", padding: 0 }}>
				<Section2 />
			</Container>
			<Container style={{ maxWidth: "1440px" }}>
				<Section3 />
				<Box sx={{ height: { xs: 35, sm: "75px" } }} />
				<Section4 />
				<Box sx={{ height: { xs: 30, sm: "75px" } }} />
				<Section5 />
				<Box sx={{ height: { xs: 50, sm: "75px" } }} />
				<Section6 />
				<Box sx={{ height: { xs: 40, sm: "75px" } }} />
				<Section7 />
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
			</Container>
			<Container style={{ maxWidth: "1440px", padding: 0 }}>
				<img
					src={assetUrls?.cowBanner}
					alt=""
					style={{ width: "100%" }}
				/>
			</Container>
			<Container style={{ maxWidth: "1440px" }}>
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
				<Section8 />
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
			</Container>
			<Container style={{ maxWidth: "1440px", padding: 0 }}>
				<img
					src={assetUrls?.banner1}
					alt=""
					style={{ width: "100%" }}
				/>
			</Container>
			<Container style={{ maxWidth: "1440px" }}>
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
				<Section9 />
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
			</Container>
			<Container style={{ maxWidth: "1440px", padding: 0 }}>
				<img
					src={assetUrls?.tractorBanner}
					alt=""
					style={{ width: "100%" }}
				/>
			</Container>
			<Container style={{ maxWidth: "1440px" }}>
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
				<Section10 />
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
				<Section11 />
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
			</Container>
			<Container style={{ maxWidth: "1440px", padding: 0 }}>
				<Section12 />
			</Container>
			<Container style={{ maxWidth: "1440px" }}>
				<Box sx={{ height: { xs: 25, sm: "50px" } }} />
				<Section13 />
			</Container>
			{openThanksModal && (
				<ThankYouModal
					open={openThanksModal}
					handleClose={() => setOpenThanksModal(false)}
				/>
			)}
		</Box>
	);
}

export default withRouter(DigitalVillage);
