import { makeStyles } from "@material-ui/core-v4";
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography, Container } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useTheme } from "@emotion/react";

import cartnshop from "../../assets/cartnshop.png";

const experiences = [
	{
		value: "YES",
		label: "YES",
	},
	{
		value: "NO",
		label: "NO",
	},
];
const products = [
	{
		value: "B2B wholesale",
		label: "B2B wholesale",
	},
	{
		value: "Fashion and Wears",
		label: "Fashion and Wears",
	},
	{
		value: "Electronics",
		label: "Electronics",
	},
	{
		value: "Furniture and Decor",
		label: "Furniture and Decor",
	},
	{
		value: "health care and Wellness",
		label: "health care and Wellness",
	},
	{
		value: "Cosmetics and Body care",
		label: "Cosmetics and Body care",
	},
	{
		value: "Food and Beverages",
		label: "Food and Beverages",
	},
	{
		value: "Office Supplies and Equipment",
		label: "Office Supplies and Equipment",
	},
	{
		value: "Digital media",
		label: "Digital media",
	},
	{
		value: "sports wear",
		label: "sports wear",
	},
	{
		value: "digital services",
		label: "digital services",
	},
	{
		value: "Toys and Hobbies",
		label: "Toys and Hobbies",
	},
	{
		value: "Pet Care",
		label: "Pet Care",
	},
	{
		value: "Household and Consumer Equipment",
		label: "Household and Consumer Equipment",
	},
	{
		value: "Auto and Auto Parts",
		label: "Auto and Auto Parts",
	},
	{
		value: "Professionals and Services",
		label: "Professionals and Services",
	},
	{
		value: "Music ,Books and Games",
		label: "Music ,Books and Games",
	},
	{
		value: "Apparels and Accessories",
		label: "Apparels and Accessories",
	},
	{
		value: "Medical and pharmaceutical Supplies",
		label: "Medical and pharmaceutical Supplies",
	},
	{
		value: "Real estate",
		label: "Real estate",
	},
	{
		value: "Rentals and Property",
		label: "Rentals and Property",
	},
	{
		value: "Others",
		label: "Others",
	},
];
// const products = [
// 	{
// 		value: "Shoes/Clothes",
// 		label: "Shoes/Clothes",
// 	},
// 	{
// 		value: "Health/Beauty",
// 		label: "Health/Beauty",
// 	},
// 	{
// 		value: "Food/Beverage",
// 		label: "Food/Beverage",
// 	},
// 	{
// 		value: "Office supplies",
// 		label: "Office supplies",
// 	},
// 	{
// 		value: "Jewellery",
// 		label: "Jewellery",
// 	},
// 	{
// 		value: "Pet care",
// 		label: "Pet care",
// 	},
// 	{
// 		value: "Books/Music ",
// 		label: "Books/Music ",
// 	},
// 	{
// 		value: "Others ",
// 		label: "Others ",
// 	},
// ];
const settingStores = [
	{
		value: "YES",
		label: "YES-I'm developing the store for a client",
	},
	{
		value: "NO",
		label: "NO",
	},
];
const webSites = [
	{
		value: "YES",
		label: "YES",
	},
	{
		value: "NO",
		label: "NO",
	},
];
const businesses = [
	{
		value: "B2B wholesale",
		label: "B2B wholesale",
	},
	{
		value: "Others",
		label: "Others",
	},
];

const useStyles = makeStyles((theme) => ({
	jcc: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "white",
	},
	mainBox: {
		maxWidth: "896px",
		height: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#04B4FF",
		borderRadius: "1em",
		textAlign: "center",
		margin: "10px!important",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "300px",
			margin: "10px",
		},
	},
	formClass: {
		width: "100%",
		height: "auto",
		marginTop: "5em",
		marginBottom: "5em",
		color: "white",
		[theme.breakpoints.down("md")]: {
			marginTop: "2em",
			marginBottom: "2em",
		},
	},
	logo: {
		width: "71px",
		height: "70px",
		cursor: "pointer",
		top: "54px",
		left: "57px",
		[theme.breakpoints.down("sm")]: {
			width: "35px",
			height: "35px",
			top: "10px",
			left: "10px",
		},
	},
	widthMedia: {
		width: "388px",
		maxHeight: "42px",
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down("sm")]: {
			width: "280px",
		},
		"& .MuiInputBase-root": {
			height: "42px",
			color: "white!important",
			// paddingTop: '8px !important',
			borderRadius: "12px!important",
		},
		"& .MuiFilledInput-input": {
			padding: "0px!important",
		},
		[theme.breakpoints.down("sm")]: {
			width: "200px!important",
			minHeight: "42px",
			display: "flex",
			justifyContent: "center",
		},
	},
	typoMedia: {
		minWidth: "300x",
		minHeight: "21px",
		fontWeight: "700!important",
		fontFamily: "Raleway !important",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "260px!important",
		},
	},
	buttonMedia: {
		width: "233px",
		height: "43px",
		fontFamily: "Raleway !important",
		backgroundColor: "#1A75FF",
		// fontWeight: '700!important',
		borderRadius: "12px!important",
		margin: "5px!important",
		marginTop: "20px!important",
		"&:hover": {
			background: "#04B4FF!important",
		},
	},
	BackButton: {
		width: "85px",
		minHeight: "43px",
		fontFamily: "Raleway !important",
		margin: "5px!important",
		marginTop: "20px!important",
		borderRadius: "12px!important",
		"&:hover": {
			background: "#04B4FF!important",
		},
	},
}));

function Information(props) {
	const { handleRandorComp } = props;
	const { handleClick } = props;
	const classes = useStyles();

	const [experience, setExperience] = useState();
	const [product, setProduct] = React.useState();
	const [store, setStore] = React.useState();
	const [website, setWebsite] = React.useState();
	const [business, setBusiness] = React.useState("");

	//   const handleChange = (event) => {
	//         setStore(event.target.value);
	//         setWebsite(event.target.value);
	//         console.log(event.target.value);
	//     };

	return (
		<React.Fragment>
			<container maxWidth='lg'>
				<img
					className={classes.logo}
					onClick={() => {
						props.history.push("/");
					}}
					src={cartnshop}
					alt='logo'
				/>
				<Container
					maxWidth='lg'
					style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<Grid item container className={classes.mainBox}>
						<form className={classes.formClass} onSubmit={handleClick}>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography
									variant='h4'
									style={{
										marginBottom: "50px",
										fontWeight: "700",
										fontFamily: "Raleway !important",
									}}>
									Tell us about yourself
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography variant='body1' className={classes.typoMedia}>
									Do you have an experience selling online?
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Box component='form' noValidate autoComplete='off' style={{ height: "60px" }}>
									<TextField
										id='outlined-select-experience'
										variant='filled'
										select
										name='experiance'
										// label="Select"
										value={experience}
										onChange={(event) => {
											setExperience(event.target.value);
											props.handleChange(event);
										}}
										className={classes.widthMedia}>
										{experiences.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
							</Grid>
							{/* <Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography variant='body1' className={classes.typoMedia}>
									What type of business you want to get into?
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Box component='form' noValidate autoComplete='off' style={{ height: "60px" }}>
									<TextField
										id='outlined-select-experience'
										variant='filled'
										select
										name='businessType'
										// label="Select"
										value={business}
										onChange={(event) => {
											setBusiness(event.target.value);
											props.handleChange(event);
										}}
										className={classes.widthMedia}>
										{businesses.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
							</Grid> */}
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography variant='body1' className={classes.typoMedia}>
									What type of products will you be selling?
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Box
									component='form'
									// sx={{
									//     '& .MuiTextField-root': { m: 1, width: '25ch' },
									// }}
									noValidate
									autoComplete='off'
									style={{ height: "60px" }}>
									<TextField
										id='outlined-select-product'
										select
										name='businessType'
										variant='filled'
										value={product}
										onChange={(event) => {
											setProduct(event.target.value);
											props.handleChange(event);
										}}
										className={classes.widthMedia}>
										{products.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography variant='body1' className={classes.typoMedia}>
									Are you setting up a store for someone else?
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Box component='form' noValidate autoComplete='off' style={{ height: "60px" }}>
									<TextField
										id='outlined-select-store'
										select
										variant='filled'
										value={store}
										onChange={(event) => {
											setStore(event.target.value);
										}}
										className={classes.widthMedia}>
										{settingStores.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Typography variant='body1' className={classes.typoMedia}>
									Do you already have a website?
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc}>
								<Box component='form' noValidate autoComplete='off' style={{ height: "60px" }}>
									<TextField
										id='outlined-select-website'
										select
										variant='filled'
										name='website'
										value={website}
										className={classes.widthMedia}
										onChange={(event) => {
											setWebsite(event.target.value);
											props.handleChange(event);
										}}>
										{webSites.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Box>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Button
									variant='contained'
									style={{ textTransform: "none" }}
									// onClick={() => props.history.push('/information')}
									className={classes.BackButton}
									onClick={() => {
										handleRandorComp("Page2");
									}}>
									Back
								</Button>
								<Button
									variant='contained'
									style={{ textTransform: "none" }}
									className={classes.buttonMedia}
									type='sumbit'>
									Finish Creating Store
								</Button>
							</Grid>
						</form>
					</Grid>
				</Container>
			</container>
		</React.Fragment>
	);
}
export default withRouter(Information);
