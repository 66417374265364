import * as React from "react";
import { Box, Button, Typography, Modal } from "@material-ui/core";
import { MdSupportAgent, MdOutlineAccountCircle } from "react-icons/md";
import successfulAnimation from "../../assets/animations/successful.json";
import Lottie from "lottie-react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "90%", sm: 600 },
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 5,
	borderRadius: "12px",
};

export default function ThankYouModal({ open, handleClose }) {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Lottie
					animationData={successfulAnimation}
					loop={true}
				/>
				<Typography
					textAlign="center"
					variant="h4"
					sx={{ color: "#1ECF35" }}
				>
					Thank you for getting in touch!
				</Typography>
			</Box>
		</Modal>
	);
}
