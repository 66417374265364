import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import assetUrls from "./assetUrls";

const infoArray = [
	{
		title: "High class services for everyone",
		desc:
			"We provide a top-notch  platform that is accessible to all sellers, customers, regardless of their budget or background.",
	},
	{
		title: "Just pay It’s that simple",
		desc:
			"The platform is designed to make payments as straight forward and user-friendly as possible.",
	},
];

function Section4(props) {
	return (
		<Box>
			<Grid
				container
				spacing={{ xs: 3, sm: 3, md: 5 }}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
				>
					<img
						src={assetUrls.img1}
						alt=""
						style={{ width: "100%", objectFit: "contain", maxHeight: 500 }}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
				>
					<Box
						sx={{
							height: "100%",
							width: "100%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Grid
							container
							spacing={3}
						>
							{infoArray?.map((ele, index) => (
								<Grid
									item
									xs={12}
									sm={6}
									key={index}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											alignItems: "flex-start",
											height: {
												xs: index === 0 ? "160px" : "130px",
												sm: "200px",
												lg: 250,
											},
											px: { xs: 0, sm: 3, md: 0 },
										}}
									>
										<Typography
											sx={{
												fontFamily: "Inter",
												fontStyle: "normal",
												fontWeight: 900,
												fontSize: { xs: "24px", sm: "30.1981px" },
												lineHeight: { xs: "28px", sm: "32px" },
												color: "#3A3A3A",
											}}
										>
											{ele?.title}
										</Typography>
										<Typography
											sx={{
												fontFamily: "Inter",
												fontStyle: "normal",
												fontWeight: 400,
												fontSize: "14.2109px",
												lineHeight: { xs: "22px", sm: "24px" },
												color: "#484848",
											}}
										>
											{ele?.desc}
										</Typography>
										<Button
											sx={{
												borderRadius: 0,
												background: "#37A3DB",
												color: "#FFF",
											}}
											disableRipple={true}
										>
											Learn More
										</Button>
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Section4;
