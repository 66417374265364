import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Button,
	Grid,
	Box,
	InputAdornment,
	Container,
	IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import cartnshop from "../../assets/cartnshop.png";
import { GetDataApi } from "./ApiServices/ApiServices";
import TextField from "@material-ui/core/TextField";
import PasswordField from "../PasswordField";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => {
	console.log(theme);
	return {
		mainContainer: {
			maxWidth: "896px",
			height: "auto",
			fontFamily: "Raleway !important",
			backgroundColor: "#04B4FF",
			display: "flex",
			borderRadius: "1em",
			justifyContent: "center",
			alignItems: "center",
			margin: "5px!important",
			// marginTop: "118px!important",
			[theme.breakpoints.down("xs")]: {
				maxWidth: "300px",
				// marginTop: "50px!important",
				margin: "5px!important",
			},
		},
		header: {
			width: "500px",
			minHeight: "35px",
			padding: "5px",
			fontFamily: "Raleway !important",
			fontSize: "30px!important",
			marginBottom: "39px",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "25px!important",
			},
		},
		logo: {
			width: "71px",
			height: "70px",
			cursor: "pointer",
			top: "54px",
			left: "57px",
			[theme.breakpoints.down("sm")]: {
				width: "35px",
				height: "35px",
				top: "10px",
				left: "10px",
			},
		},
		jcc: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: "white",
		},
		bottomButton: {
			margin: "1em",
		},
		formClass: {
			width: "100%",
			height: "auto",
			marginTop: "5em",
			marginBottom: "5em",
			color: "white",
			[theme.breakpoints.down("md")]: {
				marginTop: "2em",
				marginBottom: "2em",
			},
		},
		textMedia: {
			display: "flex",
			width: "338px",
			minHeight: "42px",
			"& .MuiInputBase-root": {
				height: "42px",
				color: "white!important",
				borderRadius: "12px",
			},
			"& .MuiInputLabel-root": {
				color: "white",
				fontFamily: "Raleway!important",
				fontWeight: "700",
			},
			[theme.breakpoints.down("sm")]: {
				width: "200px",
			},
		},
		nextButton: {
			width: "85px",
			height: "42px",
			fontFamily: "Raleway !important",
			fontWeight: "700!important",
			borderRadius: "12px!important",
			"&:hover": {
				background: "#04B4FF!important",
			},
		},
		textMediaPassword: {
			display: "flex",
			width: "338px",
			minHeight: "42px",
			"& .MuiTextField-root": {
				width: "100%!important",
				padding: "0px!important",
			},
			"& .MuiFormLabel-root": {
				position: "absolute!important",
				top: "-6px!important",
			},
			"& .MuiInputBase-root": {
				height: "42px",
				color: "white!important",
				borderRadius: "12px",
			},
			"& .MuiInputLabel-root": {
				color: "white",
				fontWeight: "700",
				fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
			},
			[theme.breakpoints.down("sm")]: {
				width: "200px",
			},
		},
	};
});

function GetStarted(props) {
	const { handleRandorComp, handleChange, value, mccode } = props;

	// const [ passMatch, setPassMatch ] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	// const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const classes = useStyles();
	const theme = useTheme();
	console.log(theme);

	const ApiCall = React.useCallback(() => {
		GetDataApi().then((res) => {
			console.log(res);
		});
	}, []);

	React.useEffect(() => {
		ApiCall();
	}, [ApiCall]);

	return (
		<React.Fragment>
			<div id="getStart">
				<container maxWidth="lg">
					<img
						className={classes.logo}
						onClick={() => {
							props.history.push("/");
						}}
						src={cartnshop}
						alt="logo"
					/>
					<Container
						maxWidth="lg"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid
							item
							container
							className={classes.mainContainer}
						>
							<form
								className={classes.formClass}
								onSubmit={(event) => {
									event.preventDefault();
									handleRandorComp("Page2");
								}}
								// onSubmit={(event) => {
								//     if(value.password === value.confirmPassword){
								//         event.preventDefault()
								//         handleRandorComp('Page2')
								//     } else {
								//         event.preventDefault()
								//         setPassMatch(true)
								//     }
								// }}
							>
								<Grid
									item
									container
									lg={12}
									md={12}
									sm={12}
									xs={12}
									className={classes.jcc}
									spacing={2}
									style={{ padding: "0px!important", margin: "0px", width: "100%" }}
								>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px", marginBottom: "39px" }}
									>
										<Typography className={classes.header}>
											<b>Get Started With a Free Account</b>
										</Typography>
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<Box
											className={classes.textMedia}
											style={{ display: "flex" }}
										>
											<Grid
												container
												spacing={2}
											>
												<Grid
													item
													lg={6}
													md={6}
													sm={6}
													xs={6}
												>
													<TextField
														// className={classes.textMedia}
														size="small"
														value={value.firstName}
														label="First Name"
														variant="filled"
														id="name"
														type="text"
														name="firstName"
														required
														onChange={handleChange}
													/>
												</Grid>
												<Grid
													item
													lg={6}
													md={6}
													sm={6}
													xs={6}
												>
													<TextField
														// className={classes.textMedia}
														size="small"
														value={value.lastName}
														label="Last Name"
														variant="filled"
														id="name"
														type="name"
														name="lastName"
														required
														onChange={handleChange}
													/>
												</Grid>
											</Grid>
										</Box>
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											className={classes.textMedia}
											size="small"
											value={value.email}
											label="Email"
											variant="filled"
											id="email"
											type="email"
											name="email"
											required
											onChange={handleChange}
											autoComplete="off"
										/>
									</Grid>
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<TextField
											className={classes.textMedia}
											size="small"
											label="Mobile Number"
											variant="filled"
											id="mobile"
											type="number"
											name="mobile"
											value={value.mobile}
											maxLength={8}
											required
											autoComplete="off"
											onInput={(e) => {
												e.target.value = Math.max(0, parseInt(e.target.value))
													.toString()
													.slice(0, 12);
											}}
											onChange={handleChange}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<span style={{ margin: "17px 5px 0 0", fontSize: "14.5px" }}>
															{mccode}
														</span>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid
										item
										lg={12}
										id="123"
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px", marginBottom: "15px" }}
									>
										<PasswordField
											minLength={5}
											minScore={2}
											scoreWords={["weak", "okay", "good", "strong", "stronger"]}
											className={classes.textMediaPassword}
											changeCallback={(e) => {
												handleChange({ target: { name: "password", value: e.password } });
											}}
											inputProps={{
												name: "password",
												variant: "filled",
												// type: 'password',
												type: showPassword ? "text" : "password",
												autoComplete: "off",
												fullWidth: true,
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
														>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									{/* <Grid item lg={12} md={12} sm={12} xs={12} className={classes.jcc} style={{padding: '0px',marginBottom:'21px'}}>
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField 
                                    className={classes.textMedia} 
                                    size="small" 
                                    label="Confirm Password"
                                    variant='filled' 
                                    value="password"
                                    id="password" 
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name='confirmPassword'
                                    required
                                    error={passMatch}
                                    onChange={handleChange}
                                />
                                    {passMatch && <span>password does not match</span>}
                                </Box>
                            </Grid> */}
									<Grid
										item
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={classes.jcc}
										style={{ padding: "0px" }}
									>
										<Button
											variant="contained"
											type="submit"
											style={{ textTransform: "none" }}
											className={classes.nextButton}
										>
											Next
										</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Container>
				</container>
			</div>
		</React.Fragment>
	);
}
export default withRouter(GetStarted);
